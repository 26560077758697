import PropTypes from 'prop-types'
import { DatePicker as AntDatePicker } from 'antd'
import styles from './DatePicker.module.css'
require('./DatePicker.less')

const DatePicker = ({
	style,
	className,
	title,
	placeholder,
	allowClear,
	autoFocus,
	popupContainer,
	disabledDate,
	value,
	error,
	onChange,
	showTime,
	renderExtraFooter,
	disabledTime,
	showNow
}) => {
	return (
		<div className={`${styles.datePicker} date-picker`}>
			<p className={`${styles.title} ${error && styles.titleError}`}>{title}</p>
			<AntDatePicker
				style={style}
				className={`date-picker-picker ${className} ${error && styles.pickerError}`}
				placeholder={placeholder}
				allowClear={allowClear}
				autoFocus={autoFocus}
				suffixIcon={
					<img className={styles.calendarIcon} src='/img/calendar-line.svg' />
				}
				getPopupContainer={() => popupContainer ? popupContainer() : document.body}
				disabledDate={disabledDate}
				value={value}
				onChange={onChange}
				showTime={showTime}
				renderExtraFooter={renderExtraFooter}
				disabledTime={disabledTime}
				showNow={showNow}
			/>
		</div>
	)
}

DatePicker.propTypes = {
	style: PropTypes.object,
	className: PropTypes.string,
	title: PropTypes.node,
	placeholder: PropTypes.string,
	allowClear: PropTypes.bool,
	autoFocus: PropTypes.bool,
	popupContainer: PropTypes.func,
	disabledDate: PropTypes.func,
	value: PropTypes.object,
	error: PropTypes.bool,
	onChange: PropTypes.func,
	showTime: PropTypes.bool,
	renderExtraFooter: PropTypes.func,
	disabledTime: PropTypes.func,
	showNow: PropTypes.bool
}

DatePicker.defaultProps = {
	placeholder: 'Select a date',
	error: false,
	showTime: false,
	showNow: true,
	renderExtraFooter: () => null
}

export default DatePicker
