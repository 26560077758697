import moment from 'moment'
import { SubscriptionCancelSource } from '../../utils/constants'
import { get, patch, post } from './index'

export const Payments = {
	index: ({
		page,
		pageSize,
		status,
		searchTerm,
		cycleStartDate,
		cycleEndDate,
		companyId,
		type,
		method
	}) =>
		get('/payments', {
			params: {
				page,
				pageSize,
				status,
				searchTerm,
				cycleStartDate,
				cycleEndDate,
				companyId,
				type,
				method
			}
		}),
	createCheckoutSession: ({
		paymentGateway,
		totalAmount,
		type,
		currency,
		customerName,
		customerPhone,
		customerAddressOne,
		customerEmail,
		smsMonth,
		smsYear,
		paymentMonth,
		paymentYear,
		subscriptionTier,
		subscriptionPeriod,
		prorationDate,
		topUpAmount,
		invoiceId
	}) =>
		post('payments/session/check-out', {
			paymentGateway,
			totalAmount,
			type,
			currency,
			customerName,
			customerPhone,
			customerAddress1: customerAddressOne,
			customerEmail,
			smsMonth,
			smsYear,
			paymentMonth,
			paymentYear,
			subscriptionTier,
			subscriptionPeriod,
			prorationDate,
			topUpAmount,
			invoiceId
		}),
	createCheckoutSessionAtSignUp: ({
		companyId,
		paymentGateway,
		totalAmount,
		type,
		currency,
		customerName,
		customerPhone,
		customerAddressOne,
		customerEmail,
		paymentMonth,
		paymentYear,
		subscriptionTier,
		subscriptionPeriod
	}) =>
		post('payments/session/check-out/sign-up', {
			companyId,
			paymentGateway,
			totalAmount,
			type,
			currency,
			customerName,
			customerPhone,
			customerAddress1: customerAddressOne,
			customerEmail,
			paymentMonth,
			paymentYear,
			subscriptionTier,
			subscriptionPeriod
		}),
	createCustomerPortalSession: () =>
		post('payments/session/customer-portal'),
	getCustomer: () =>
		get('payments/customer'),
	getSubscriptions: () =>
		get('payments/subscriptions'),
	fetchAllPayments: (page, pageSize, status) =>
		get('/payments', {
			params: {
				page,
				pageSize,
				status
			}
		}),
	fetchSinglePayment: (id) =>
		get(`/payments/single/${id}`),
	fetchAllPaymentsByCompany: ({
		companyId,
		page,
		pageSize
	}) =>
		get(`payments/${companyId}`, {
			params: {
				page,
				pageSize
			}
		}),
	updatePayments: ({
		paymentsToUpdate,
		status,
		reference,
		remarks
	}) =>
		patch('payments', {
			paymentsToUpdate,
			status,
			reference,
			remarks
		}),
	fetchPricingForCompany: (id, subscriptionPlan, subscriptionPeriod) =>
		get(`/payments/${id}/company-pricing`, {
			params: {
				subscriptionPlan,
				subscriptionPeriod
			}
		}),
	fetchProratedPaymentAmount: (id, subscriptionPlan, subscriptionPeriod) =>
		get(`/payments/${id}/subscription-proration`, {
			params: {
				subscriptionPlan,
				subscriptionPeriod
			}
		}),
	fetchLatestInvoiceAmount: (id) =>
		get(`/payments/${id}/latest-invoice-amount`),
	adminCancelSubscription: (companyId, subscriptionId, remarks, source = SubscriptionCancelSource.ADMIN) =>
		post(`payments/${companyId}/admin-subscription-cancellation`, {
			subscriptionId,
			remarks,
			source
		}),
	updateSubscription: (companyId, subscriptionTier, subscriptionPeriod, prorationDate) =>
		patch(`payments/${companyId}/subscription`, {
			subscriptionTier,
			subscriptionPeriod,
			prorationDate
		}),
	fetchCompanyBalance: (companyId) =>
		get(`payments/${companyId}/company-balance`),
	getSubscriptionPricing: (subscriptionTier, subscriptionPeriod, country) =>
		get(
			'payments/subscription-pricing',
			{
				params: {
					subscriptionPlan: subscriptionTier,
					subscriptionPeriod,
					country
				}
			}
		),
	adminCreateSubscription: (companyId, subscriptionTier, subscriptionPeriod) =>
		post(`payments/${companyId}/admin-subscription`, {
			subscriptionTier,
			subscriptionPeriod
		}),
	adminCreateTrial: (companyId, subscriptionTier) =>
		post(`payments/${companyId}/admin-trial`, {
			subscriptionTier
		}),
	updateCustomPricingAndLimits: (id, { customPricing, limits }) =>
		patch(`/payments/${id}/custom-pricing-and-limits`, {
			customPricing,
			limits
		}),
	updateTrialEnd: (id, newTrialEnd) =>
		patch(`/payments/${id}/subscription-trial`, {
			newTrialEnd
		}),
	billingInvoiceIndex: ({
		page = 0,
		pageSize,
		status,
		searchTerm,
		cycleStartDate,
		cycleEndDate,
		companyId
	} = {}) =>
		get('/payments/invoices', {
			params: {
				page,
				pageSize,
				status,
				searchTerm,
				cycleStartDate,
				cycleEndDate,
				companyId
			}
		}),
	fetchSingleInvoice: (id) =>
		get(`/payments/invoice/single/${id}`),
	fetchLatestInvoice: () =>
		get('/payments/invoice/latest'),
	updateBillingInvoices: ({
		invoicesToUpdate,
		status,
		paymentMethod,
		referenceId,
		remarks
	}) =>
		patch('/payments/invoices/status', {
			invoicesToUpdate,
			status,
			paymentMethod,
			referenceId,
			remarks
		}),
	updateBillingInvoiceDetails: ({
		invoiceId,
		amount,
		taxRate,
		vatRate,
		items
	}) =>
		patch('/payments/invoice/details', {
			invoiceId,
			amount,
			taxRate,
			vatRate,
			items
		}),
	updateTemporaryAccess: ({
		cid,
		expiresAt,
		remove
	}) =>
		patch(`/payments/${cid}/temporary-access`, {
			expiresAt,
			remove
		}),
	createOneTimeInvoice: ({
		companyId,
		dueDate,
		currency,
		amount,
		taxRate,
		vatRate,
		serviceDescription
	}) =>
		post('/payments/invoice/one-time', {
			companyId,
			dueDate,
			currency,
			amount,
			taxRate,
			vatRate,
			serviceDescription
		}),
	createUpsellInvoice: ({
		companyId,
		dueDate,
		currency,
		amount,
		taxRate,
		vatRate,
		items
	}) =>
		post('/payments/invoice/upsell', {
			companyId,
			dueDate,
			currency,
			amount,
			taxRate,
			vatRate,
			items
		}),
	fetchUnpaidOneTimeInvoiceForCompany: (companyId) =>
		get(`/payments/invoice/unpaid-one-time/${companyId}`),
	fetchUnpaidUpsellInvoices: (companyId) =>
		get(`/payments/invoice/unpaid-upsell/${companyId}`),
	exportInvoicesCsv: async ({
		invoiceStatus,
		fromDate,
		toDate,
		filterBy
	}) => {
		const response = await get('/payments/billing-invoices/csv', {
			responseType: 'blob',
			params: {
				invoiceStatus,
				filterBy,
				fromDate,
				toDate
			}
		})
		const link = document.createElement('a')
		link.href = window.URL.createObjectURL(response.data)
		link.download = `billing_invoices_${invoiceStatus?.join('_').toLowerCase()}_${moment(new Date()).format('YYYY-MM-DD')}.csv`
		link.click()
		link.remove()
	},
	exportPaymentsCsv: async ({
		paymentStatus,
		fromDate,
		toDate
	}) => {
		const response = await get('/payments/billing-payments/csv', {
			responseType: 'blob',
			params: {
				paymentStatus,
				fromDate,
				toDate
			}
		})
		const link = document.createElement('a')
		link.href = window.URL.createObjectURL(response.data)
		link.download = `billing_payments_${paymentStatus?.join('_').toLowerCase()}_${moment(new Date()).format('YYYY-MM-DD')}.csv`
		link.click()
		link.remove()
	}
}
