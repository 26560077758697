import { Modal, notification, Table } from 'antd'
import CustomSearchableSelect from '../custom-searchable-select'
import { DeliveryPartners } from '../../services/api/delivery-partner'
import { useEffect, useMemo, useRef, useState } from 'react'
import Input from '../input'
import styles from './PrintShipmentModal.module.css'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { dateTimeDisplayFormats } from '../../utils/constants'
import SalesOrders from '../../services/api/sales-orders'
import ReactToPrint from 'react-to-print'
import { getFormattedCurrency } from '../../utils'
require('./PrintShipmentModal.less')

const columns = [
	{
		title: 'SL',
		key: 'sl',
		align: 'center',
		render: (item, record, index) => {
			return (
				<div style={{ textAlign: 'center', fontSize: 10 }}>
					{item.sl ? item.sl : index + 1}
				</div>
			)
		}
	},
	{
		title: 'In-Transit Date',
		key: 'inTransitDate',
		align: 'center',
		render: (item) => {
			return (
				<div style={{ textAlign: 'center', fontSize: 10 }}>
					{moment(item.inTransitDate).format('MMM DD, YYYY')}
				</div>
			)
		}
	},
	{
		title: 'Invoice No',
		key: 'invoiceNo',
		align: 'center',
		render: (item) => {
			return (
				<div style={{ textAlign: 'center', fontSize: 10 }}>
					{item.internalId}
				</div>
			)
		}
	},
	{
		title: 'Delivery ID',
		key: 'deliveryId',
		align: 'center',
		render: (item) => {
			const consignment = item.deliveryConsignment
			const deliveryId = consignment?.id || item.deliveryOrderId || item.deliveryTrackingId
			return (
				<div style={{ textAlign: 'center', fontSize: 10 }}>
					{deliveryId}
				</div>
			)
		}
	},
	{
		title: 'Name',
		key: 'name',
		align: 'center',
		render: (item) => {
			const distributor = item.distributor
			return (
				<div style={{ textAlign: 'center', fontSize: 10 }}>
					{distributor?.name}
				</div>
			)
		}
	},
	{
		title: 'Mobile No.',
		key: 'mobileNo',
		align: 'center',
		render: (item) => {
			const distributor = item.distributor
			return (
				<div style={{ textAlign: 'center', fontSize: 10 }}>
					{distributor?.phone}
				</div>
			)
		}
	},
	{
		title: 'COD Amount',
		key: 'name',
		align: 'center',
		render: (item) => {
			const dueAmount = +item.totalDueAmount - +item.totalPaidAmount
			return (
				<div style={{ textAlign: 'center', fontSize: 10 }}>
					{getFormattedCurrency(dueAmount)}
				</div>
			)
		}
	}
]

const PrintShipmentModal = ({ visible, onCancel }) => {
	const { userProfile } = useSelector(state => state.authReducer)
	const printableRef = useRef()
	const printRef = useRef()
	const [isLoadingDeliveryPartners, setIsLoadingDeliveryPartners] = useState(false)
	const [isLoadingSalesOrders, setIsLoadingSalesOrders] = useState(false)
	const [salesOrders, setSalesOrders] = useState([])
	const [totalDueAmount, setTotalDueAmount] = useState(0)
	const [deliveryPartner, setDeliveryPartner] = useState(null)
	const [deliveryPartners, setDeliveryPartners] = useState([])
	const [fromDate, setFromDate] = useState()
	const [toDate, setToDate] = useState()
	const { preferences } = useSelector(state => state.authReducer)
	const isDateTimeFormat = preferences?.dateTimeDisplayFormat === dateTimeDisplayFormats.DATE_TIME
	const timeFormat = isDateTimeFormat ? 'YYYY-MM-DD h:mm A' : 'YYYY-MM-DD'

	useEffect(() => {
		if (visible) {
			getDeliveryPartners()
		}
	}, [visible])

	const pages = useMemo(() => {
		const pageSize = 20
		const pages = []
		for (let i = 0; i < salesOrders.length; i += pageSize) {
			const chunk = salesOrders.slice(i, i + pageSize)
			pages.push({
				items: chunk
			})
		}
		return pages
	}, [salesOrders])

	const getDeliveryPartners = async () => {
		try {
			setIsLoadingDeliveryPartners(true)
			const { data } = await DeliveryPartners.index({
				page: -1
			})
			const deliveryPartners = data.results
			setDeliveryPartners(deliveryPartners)
			setDeliveryPartner(null)
		} catch (err) {
			notification.error({
				message: 'Unable to Fetch Delivery Partners',
				description: err.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoadingDeliveryPartners(false)
		}
	}

	const searchDeliveryPartners = value => {
		return DeliveryPartners.index({
			searchTerm: value
		})
	}

	const onDeliveryPartnerSelected = data => {
		const newSelectedDeliveryPartner = { value: data.id, label: data.name }
		setDeliveryPartner(newSelectedDeliveryPartner)
	}

	const onPrint = async () => {
		await getSalesOrders()
	}

	const getSalesOrders = async () => {
		try {
			setIsLoadingSalesOrders(true)
			const response = await SalesOrders.index({
				preferredDeliveryPartner: deliveryPartner.value,
				inTransitFromDate: isDateTimeFormat ? fromDate : moment(fromDate).startOf('day').toDate(),
				inTransitToDate: isDateTimeFormat ? toDate : moment(toDate).endOf('day').toDate()
			})
			const { results } = response.data
			setSalesOrders(results)

			if (!results.length) {
				throw new Error('No Orders Found')
			}

			let totalDueAmount = 0
			for (let i = 0; i < salesOrders.length; i++) {
				const salesOrder = salesOrders[i]
				salesOrder.sl = i + 1
				totalDueAmount += +salesOrder.totalDueAmount - +salesOrder.totalPaidAmount
			}
			setTotalDueAmount(totalDueAmount)
			setTimeout(() => {
				printRef.current.handleClick()
			}, 1500)
		} catch (e) {
			console.error(e)
			notification.error({
				message: 'Unable to Print PDF',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoadingSalesOrders(false)
		}
	}

	return (
		<Modal
			visible={visible}
			onCancel={onCancel}
			width={538}
			okText='Print PDF'
			okButtonProps={{
				loading: isLoadingSalesOrders,
				disabled: !deliveryPartner || !fromDate || !toDate
			}}
			onOk={onPrint}
			title='Print Shipment PDF'
		>
			<div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
				<CustomSearchableSelect
					searchTask={searchDeliveryPartners}
					defaultOptions={deliveryPartners}
					isLoading={isLoadingDeliveryPartners}
					title='Delivery Partner'
					labelIndex='name'
					valueIndex='id'
					placeholder='Select Delivery Partner'
					onChangeData={onDeliveryPartnerSelected}
					onClear={() => setDeliveryPartner(null)}
					value={deliveryPartner}
					popupContainer={() => document.querySelector('.ant-modal-body')}
				/>
				<div>
					<p className={styles.title}>In-Transit Date Range</p>
					<div className={styles.inputFlex}>
						<Input
							style={{ background: '#F7F7F7', borderRadius: 0, width: '100%' }}
							type='date'
							title='Start Date'
							value={fromDate ? moment(fromDate) : null }
							onChange={(date) => setFromDate(date?.toDate())}
							popupContainer={() => document.querySelector('.ant-modal-body')}
							showTime={isDateTimeFormat && {
								use12Hours: true
							}}
							format={timeFormat}
						/>
						<Input
							style={{ background: '#F7F7F7', borderRadius: 0, width: '100%' }}
							type='date'
							title='End Date'
							value={toDate ? moment(toDate) : null }
							onChange={(date) => setToDate(date?.toDate())}
							disabledDate={current => current && current.valueOf() < moment(fromDate)}
							popupContainer={() => document.querySelector('.ant-modal-body')}
							showTime={isDateTimeFormat && {
								use12Hours: true
							}}
							format={timeFormat}
						/>
					</div>
				</div>
				{
					deliveryPartner && fromDate && toDate &&
					<div ref={printableRef}>
						{
							pages.map((page, index) => {
								return (
									<div className={styles.pageInvisible} key={index}>
										<div className={styles.header}>
											<div>Delivery Partner: {deliveryPartner.label}</div>
											<div>In-Transit Date: {moment(fromDate).format('MMM DD, YYYY')} - {moment(toDate).format('MMM DD, YYYY')}</div>
											<div>Total Orders: {salesOrders.length}</div>
											<div>Total COD Amount: {getFormattedCurrency(totalDueAmount)}</div>
										</div>
										<Table
											style={{ marginTop: 24, height: '100%' }}
											size='small'
											dataSource={page.items}
											columns={columns}
											pagination={false}
											locale={{ emptyText: ' ' }}
											className='shipment-table'
										/>
										<div className={styles.footer}>
											<div className={styles.signature}>
												<div className={styles.line} />
												<div>Authorized Signature</div>
												<div>{userProfile?.company?.name}</div>
											</div>
											<div className={styles.signature}>
												<div className={styles.line} />
												<div>Authorized Signature</div>
												<div>{deliveryPartner.label}</div>
											</div>
										</div>
									</div>
								)
							})
						}
					</div>
				}
			</div>
			<ReactToPrint
				ref={printRef}
				content={() => printableRef.current}
			/>
		</Modal>
	)
}

export default PrintShipmentModal
