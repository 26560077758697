import { DeliveryPartners } from '../services/api/delivery-partner'
import { PartnerType, deliveryPartnerTrackingBaseURLs } from './constants'

export const determineDeliveryCharge = async ({ deliveryPartnerId, warehouseId, locationId, deliveryType } = {}) => {
	try {
		if (!locationId || !warehouseId || !deliveryType) {
			return {
				deliveryRegion: null,
				deliveryCharge: 0
			}
		}

		const { data } = await DeliveryPartners.getCharge(deliveryPartnerId, { warehouseId, locationId, deliveryType })
		return {
			deliveryRegion: data.region,
			deliveryCharge: +data.charge
		}
	} catch (e) {
		return {
			deliveryRegion: null,
			deliveryCharge: 0
		}
	}
}

export const getDeliveryPartnerOrderTrackingURL = (partnerType, { trackingCode, trackingUrl, phone } = {}) => {
	switch (partnerType) {
		case PartnerType.STEADFAST:
		case PartnerType.PIDEX:
			return `${deliveryPartnerTrackingBaseURLs[partnerType]}/${trackingCode}`
		case PartnerType.PATHAO:
			return `${deliveryPartnerTrackingBaseURLs[partnerType]}?consignment_id=${trackingCode}&phone=${phone?.replace('+88', '')}`
		case PartnerType.REDX:
			return `${deliveryPartnerTrackingBaseURLs[partnerType]}?trackingId=${trackingCode}`
		case PartnerType.E_COURIER:
			return `${deliveryPartnerTrackingBaseURLs[partnerType]}?tracking_no=${trackingCode}`
		case PartnerType.RIDER_COURIER:
			return `${deliveryPartnerTrackingBaseURLs[partnerType]}/${trackingCode}`
		case PartnerType.BLUE_EX:
			return `${deliveryPartnerTrackingBaseURLs[partnerType]}?cn=${trackingCode}`
		case PartnerType.MOVEX:
			return trackingUrl
		case PartnerType.PAPERFLY:
			return `${deliveryPartnerTrackingBaseURLs[partnerType]}/${trackingCode}`
		case PartnerType.PANDAGO:
			return `${deliveryPartnerTrackingBaseURLs[partnerType]}/${trackingCode}`
		case PartnerType.XPRESS_IN_TOWN:
			return trackingUrl
		default:
			return ''
	}
}

export const convertDeliveryAreaOptionToDeliveryArea = (deliveryAreaOption, partnerType) => {
	if (!deliveryAreaOption || !partnerType) {
		return null
	}
	const { division, district, pickupAddress, originCity, city, zone, area, thana, postCode, serviceType } = deliveryAreaOption
	switch (partnerType) {
		case PartnerType.REDX:
			return {
				divisionId: division?.value || null,
				divisionName: division?.label || null,
				districtId: district?.value || null,
				districtName: district?.label || null,
				areaId: area?.value || null,
				areaName: area?.label || null
			}
		case PartnerType.E_COURIER:
			return {
				cityId: city?.value || null,
				cityName: city?.label || null,
				areaId: area?.value || null,
				areaName: area?.label || null,
				thanaId: thana?.value || null,
				thanaName: thana?.label || null,
				postCodeId: postCode?.value || null,
				postCodeName: postCode?.label || null
			}
		case PartnerType.PATHAO:
		case PartnerType.PIDEX:
			return {
				cityId: city?.value || null,
				cityName: city?.label || null,
				zoneId: zone?.value || null,
				zoneName: zone?.label || null,
				areaId: area?.value || null,
				areaName: area?.label || null
			}
		case PartnerType.TCS_COURIER:
			return {
				originCityId: originCity?.value || null,
				originCityName: originCity?.label || null,
				cityId: city?.value || null,
				cityName: city?.label || null
			}
		case PartnerType.RIDER_COURIER:
			return {
				originCityId: originCity?.value || null,
				originCityName: originCity?.label || null,
				cityId: city?.value || null,
				cityName: city?.label || null,
				areaId: area?.value || null,
				areaName: area?.label || null
			}
		case PartnerType.POST_EX:
		case PartnerType.MP_COURIER:
			return {
				pickupAddressId: pickupAddress?.value || null,
				pickupAddressName: pickupAddress?.label || null,
				cityId: city?.value || null,
				cityName: city?.label || null
			}
		case PartnerType.LEOPARDS:
			return {
				cityId: city?.value || null,
				cityName: city?.label || null
			}
		case PartnerType.BLUE_EX:
			return {
				cityId: city?.value || null,
				cityName: city?.label || null,
				serviceTypeId: serviceType?.value || null,
				serviceTypeName: serviceType?.label || null
			}
		case PartnerType.MOVEX:
			return {
				areaId: area?.value || null,
				areaName: area?.label || null
			}
		case PartnerType.PAPERFLY:
			return {
				zoneName: zone?.value || null,
				areaName: area?.value || null
			}
		case PartnerType.XPRESS_IN_TOWN:
			return {
				divisionId: division?.value || null,
				divisionName: division?.label || null,
				cityId: city?.value || null,
				cityName: city?.label || null,
				zoneId: zone?.value || null,
				zoneName: zone?.label || null,
				areaId: area?.value || null,
				areaName: area?.label || null
			}
		default:
			return null
	}
}

export const convertDeliveryAreaToDeliveryAreaOption = (deliveryArea, partnerType) => {
	if (!deliveryArea || !partnerType) {
		return null
	}

	const {
		divisionId,
		divisionName,
		districtId,
		districtName,
		originCityId,
		originCityName,
		cityId,
		cityName,
		zoneId,
		zoneName,
		areaId,
		areaName,
		thanaId,
		thanaName,
		postCodeId,
		postCodeName,
		pickupAddressId,
		pickupAddressName,
		serviceTypeId,
		serviceTypeName
	} = deliveryArea
	switch (partnerType) {
		case PartnerType.REDX:
			return {
				division: divisionId && divisionName
					? {
						value: divisionId,
						label: divisionName
					}
					: null,
				district: districtId && districtName
					? {
						value: districtId,
						label: districtName
					}
					: null,
				area: areaId && areaName
					? {
						value: areaId,
						label: areaName
					}
					: null
			}
		case PartnerType.E_COURIER:
			return {
				city: cityId && cityName
					? {
						value: cityId,
						label: cityName
					}
					: null,
				area: areaId && areaName
					? {
						value: areaId,
						label: areaName
					}
					: null,
				thana: thanaId && thanaName
					? {
						value: thanaId,
						label: thanaName
					}
					: null,
				postCode: postCodeId && postCodeName
					? {
						value: postCodeId,
						label: postCodeName
					}
					: null
			}
		case PartnerType.PATHAO:
		case PartnerType.PIDEX:
			return {
				city: cityId && cityName
					? {
						value: cityId,
						label: cityName
					}
					: null,
				zone: zoneId && zoneName
					? {
						value: zoneId,
						label: zoneName
					}
					: null,
				area: areaId && areaName
					? {
						value: areaId,
						label: areaName
					}
					: null
			}
		case PartnerType.TCS_COURIER:
			return {
				originCity: originCityId && originCityName
					? {
						value: originCityId,
						label: originCityName
					}
					: null,
				city: cityId && cityName
					? {
						value: cityId,
						label: cityName
					}
					: null
			}
		case PartnerType.RIDER_COURIER:
			return {
				originCity: originCityId && originCityName
					? {
						value: originCityId,
						label: originCityName
					}
					: null,
				city: cityId && cityName
					? {
						value: cityId,
						label: cityName
					}
					: null,
				area: areaId && areaName
					? {
						value: areaId,
						label: areaName
					}
					: null
			}
		case PartnerType.POST_EX:
		case PartnerType.MP_COURIER:
			return {
				pickupAddress: pickupAddressId && pickupAddressName
					? {
						value: pickupAddressId,
						label: pickupAddressName
					}
					: null,
				city: cityId && cityName
					? {
						value: cityId,
						label: cityName
					}
					: null
			}
		case PartnerType.LEOPARDS:
			return {
				city: cityId && cityName
					? {
						value: cityId,
						label: cityName
					}
					: null
			}
		case PartnerType.BLUE_EX:
			return {
				city: cityId && cityName
					? {
						value: cityId,
						label: cityName
					}
					: null,
				serviceType: serviceTypeId && serviceTypeName
					? {
						value: serviceTypeId,
						label: serviceTypeName
					}
					: null
			}
		case PartnerType.MOVEX:
			return {
				area: areaId && areaName
					? {
						value: areaId,
						label: areaName
					}
					: null
			}
		case PartnerType.PAPERFLY:
			return {
				zone: zoneName
					? {
						value: zoneName,
						label: zoneName
					}
					: null,
				area: areaName
					? {
						value: areaName,
						label: areaName
					}
					: null
			}
		case PartnerType.XPRESS_IN_TOWN:
			return {
				division: divisionId && divisionName
					? {
						value: divisionId,
						label: divisionName
					}
					: null,
				city: cityId && cityName
					? {
						value: cityId,
						label: cityName
					}
					: null,
				zone: zoneId && zoneName
					? {
						value: zoneId,
						label: zoneName
					}
					: null,
				area: areaId && areaName
					? {
						value: areaId,
						label: areaName
					}
					: null
			}
		default:
			return null
	}
}
