export const paths = {
	HOME: '/',
	DASHBOARD: '/',
	FORECAST: '/forecast',
	INSIGHTS: '/insights',
	DISTRIBUTORS: '/distributors',
	CUSTOMERS: '/customers',
	PRODUCTS: '/products',
	INVENTORY: '/inventory',
	INVENTORY_PRODUCTS: '/inventory-products',
	REPORTS: '/reports',
	USERS: '/users',
	COMPANY: '/company',
	COMPANIES: '/companies',
	SALES_ORDERS: '/sales-orders',
	DELIVERY_PLANS: '/delivery-plans',
	FLEET: '/fleet',
	VENDORS: '/vendors',
	PURCHASE_ORDERS: '/purchase-orders',
	LOCATIONS: '/locations',
	SETTINGS: '/settings',
	WAREHOUSES: '/warehouses',
	GENERATE_LABELS: '/generate-labels',
	TRANSPORT_VENDORS: '/transport-vendors',
	TRANSPORT_QUOTATIONS: '/transport-quotations',
	DELIVERY_PARTNERS: '/delivery-partners',
	PERMISSIONS: '/permissions',
	ADD_TRANSPORT_QUOTATION: '/add-transport-quotation',
	RECYCLE_BIN: '/recycle-bin',
	WAREHOUSE: '/warehouse',
	EXPENSES: '/expenses',
	CONFIGURE_WAREHOUSE: '/configure-warehouse',
	PAYMENTS: '/company/payments',
	SUBSCRIBE_NOW: '/company/payments/subscribe-now',
	TOP_UP_SMS: '/company/payments/top-up-sms',
	TOP_UP_CCL: '/company/payments/top-up-ccl',
	RENEW_SUBSCRIPTION: '/company/payments/renew-subscription',
	MAKE_ONE_TIME_PAYMENT: '/company/payments/make-one-time-payment',
	MAKE_UPSELL_PAYMENT: '/company/payments/make-upsell-payment',
	BILLING_AND_PAYMENTS:'/billing-and-payments',
	CUSTOMERS_TRANSACTIONS:'/customers-transactions',
	BAD_DATA: '/bad-data',
	ADMIN_DASHBOARD: '/admin-dashboard'
}

export const roles = {
	ADMIN: 'ADMIN',
	OWNER: 'OWNER',
	USER: 'USER',
	DRIVER: 'DRIVER',
	HELPER: 'HELPER',
	DISTRIBUTOR: 'DISTRIBUTOR',
	SALES_MANAGER: 'SALES_MANAGER',
	PURCHASE_MANAGER: 'PURCHASE_MANAGER',
	DOCTOR: 'DOCTOR'
}

export const claimsRoles = {
	ADMIN: 'admin',
	OWNER: 'owner',
	USER: 'user',
	DRIVER: 'driver',
	HELPER: 'helper',
	SALES_MANAGER: 'sales_manager',
	PURCHASE_MANAGER: 'purchase_manager',
	DOCTOR: 'doctor'
}

export const customerTypes = {
	DISTRIBUTOR: 'DISTRIBUTOR',
	DEALER: 'DEALER',
	RETAILER: 'RETAILER',
	E_COMMERCE_CUSTOMER: 'E_COMMERCE_CUSTOMER',
	TRADER: 'TRADER',
	MICRO_ENTREPRENEUR: 'MICRO_ENTREPRENEUR',
	CORPORATE: 'CORPORATE'
}

export const orderSources = {
	PHONE_CALL: 'PHONE_CALL',
	FACEBOOK: 'FACEBOOK',
	MESSENGER: 'MESSENGER',
	WHATSAPP: 'WHATSAPP',
	TIKTOK: 'TIKTOK',
	INSTAGRAM: 'INSTAGRAM',
	OFFLINE: 'OFFLINE',
	UP_SELL: 'UP_SELL',
	WEBSITE: 'WEBSITE',
	LIVE_CHAT: 'LIVE_CHAT',
	TELESALES: 'TELESALES',
	OTHER: 'OTHER'
}

export const PartnerType = {
	E_COURIER: 'E_COURIER',
	STEADFAST: 'STEADFAST',
	PATHAO: 'PATHAO',
	CUSTOM: 'CUSTOM',
	PAKISTAN_POST: 'PAKISTAN_POST',
	REDX: 'REDX',
	PAPERFLY: 'PAPERFLY',
	PIDEX: 'PIDEX',
	TCS_COURIER: 'TCS_COURIER',
	RIDER_COURIER: 'RIDER_COURIER',
	POST_EX: 'POST_EX',
	MP_COURIER: 'MP_COURIER',
	LEOPARDS: 'LEOPARDS',
	BLUE_EX: 'BLUE_EX',
	MOVEX: 'MOVEX',
	PANDAGO: 'PANDAGO',
	XPRESS_IN_TOWN: 'XPRESS_IN_TOWN'
}

export const partnerTypeNames = {
	E_COURIER: 'E-Courier',
	STEADFAST: 'Steadfast',
	PATHAO: 'Pathao',
	REDX: 'RedX',
	PAPERFLY: 'Paperfly',
	PIDEX: 'Pidex',
	TCS_COURIER: 'TCS Courier',
	RIDER_COURIER: 'Rider Courier',
	POST_EX: 'PostEx',
	MP_COURIER: 'M & P Courier',
	LEOPARDS: 'Leopards',
	BLUE_EX: 'BlueEx',
	MOVEX: 'MoveX',
	XPRESS_IN_TOWN: 'Xpress In Town'
}

export const deliveryOrderStatuses = {
	[PartnerType.STEADFAST]: {
		UNDEFINED: 'undefined',
		PENDING: 'pending',
		APPROVAL_PENDING: 'approval_pending',
		IN_REVIEW: 'in_review',
		HOLD: 'hold',
		UNKNOWN: 'unknown',
		CANCELLED: 'cancelled',
		DELIVERED: 'delivered',
		PARTLY_DELIVERED: 'partly_delivered',
		PARTIAL_DELIVERED: 'partial_delivered'
	},
	[PartnerType.PATHAO]: {
		UNDEFINED: 'undefined',
		PICKUP_REQUESTED: 'pickup_requested',
		ASSIGNED_FOR_PICKUP: 'assigned_for_pickup',
		PICKED: 'picked',
		PICKUP_FAILED: 'pickup_failed',
		PICKUP_CANCELLED: 'pickup_cancelled',
		AT_THE_SORTING_HUB: 'at_the_sorting_hub',
		IN_TRANSIT: 'in_transit',
		RECEIVED_AT_LAST_MILE_HUB: 'received_at_last_mile_hub',
		ASSIGNED_FOR_DELIVERY: 'assigned_for_delivery',
		DELIVERED: 'delivered',
		PARTIAL_DELIVERY: 'partial_delivery',
		RETURN: 'return',
		DELIVERY_FAILED: 'delivery_failed',
		ON_HOLD: 'on_hold',
		PAYMENT_INVOICE: 'payment_invoice',
		PAID_RETURN: 'paid_return',
		EXCHANGE: 'exchange',
		ORDER_CREATED: 'order_created',
		ORDER_UPDATED: 'order_updated'
	},
	[PartnerType.PIDEX]: {
		UNDEFINED: 'undefined',
		OUT_FOR_DELIVERY: 'out_for_delivery',
		RETURNED: 'returned',
		DELIVERED: 'delivered',
		ORDER_PLACED: 'order_placed',
		PICK_UP_REQUESTED: 'pickup_requested',
		PICKED_UP: 'picked_up',
		PICKUP_CANCELLED: 'pickup_cancelled',
		IN_TRANSIT: 'in_transit',
		RETURN_IN_PROGRESS: 'return_in_progress'
	},
	[PartnerType.REDX]: {
		UNDEFINED: 'undefined',
		ORDER_PLACED: 'order_placed',
		DELIVERED: 'delivered',
		DELETED: 'deleted',
		RETURNING: 'returning',
		DAMAGED: 'damaged',
		DELIVERY_IN_PROGRESS: 'delivery_in_progress',
		RETURNED: 'returned',
		SETTLED: 'settled',
		REJECTED: 'rejected',
		PICKED_UP: 'picked_up',
		PICKUP_PENDING: 'pickup_pending',
		ON_HOLD: 'on_hold',
		PICKUP_HOLD: 'pickup_hold',
		SHOPUP_RETURNING_APPROVE_PENDING: 'shopup_returning_approve_pending'
	},
	[PartnerType.E_COURIER]: {
		UNDEFINED: 'undefined',
		ORDER_PLACED: 'order_placed',
		AGENT_ASSIGN_TO_RECEIVE_CANCEL_PARCEL_FROM_ASSISTED_POINT: 'agent_assign_to_receive_cancel_parcel_from_assisted_point',
		ASSIGN_AGENT_CANCELLED: 'assign_agent_cancelled',
		ASSIGN_BRANCH_CANCELLED: 'assign_branch_cancelled',
		AT_FULFILLMENT_WRONG_ROUTING: 'at_fulfillment_wrong_routing',
		AT_FULFILLMENT_BRANCH_TRANSFER: 'at_fulfillment_branch_transfer',
		BRANCH_ASSIGNED: 'branch_assigned',
		CANCEL_BY_AGENT: 'cancel_by_agent',
		CANCEL_BY_ASSISTED_POINT: 'cancel_by_assisted_point',
		CANCEL_BY_SUB_HUB_AGENT: 'cancel_by_sub_hub_agent',
		CANCEL_RECEIVED_AT_HUB: 'cancel_received_at_hub',
		CANCEL_RECEIVED_AT_SUB_HUB: 'cancel_received_at_sub_hub',
		CANCEL_RECEIVED_FROM_ASSISTED_POINT: 'cancel_received_from_assisted_point',
		CANCEL_RECEIVED_FROM_SUB_HUB: 'cancel_received_from_sub_hub',
		DAMAGED_OR_LOST_BY_ECOURIER: 'damaged_or_lost_by_ecourier',
		DAMAGED_OR_LOST_BY_FRANCHISE: 'damaged_or_lost_by_franchise',
		DELETED: 'deleted',
		DELIVERED: 'delivered',
		DELIVERY_AGENT_ASSIGNED: 'delivery_agent_assigned',
		DELIVERY_AGENT_ASSIGNED_RETURN: 'delivery_agent_assigned_return',
		DELIVERY_AGENT_ASSIGNED_TO_ASSISTED_POINT: 'delivery_agent_assigned_to_assisted_point',
		DELIVERY_AGENT_ASSIGNED_TO_SUB_HUB: 'delivery_agent_assigned_to_sub_hub',
		EXCHANGE_DELIVERY: 'exchange_delivery',
		HOLD_AT_FULFILLMENT: 'hold_at_fulfillment',
		HOLD_AT_HUB: 'hold_at_hub',
		HOLD_AT_HUB_BRANCH_TRANSFER: 'hold_at_hub_branch_transfer',
		HOLD_AT_HUB_RETURN: 'hold_at_hub_return',
		HOLD_AT_HUB_WRONG_ROUTING: 'hold_at_hub_wrong_routing',
		HOLD_AT_HUB_ON_MERCHANT_INSTRUCTION: 'hold_at_hub_on_merchant_instruction',
		HOLD_BY_AGENT: 'hold_by_agent',
		HOLD_BY_AGENT_RETURN: 'hold_by_agent_return',
		HOLD_INITIATED: 'hold_initiated',
		IN_DESTINATION_HUB: 'in_destination_hub',
		IN_DESTINATION_HUB_RETURN: 'in_destination_hub_return',
		INITIATED: 'initiated',
		INITIATED_API: 'initiated_api',
		ON_THE_WAY_TO_ASSISTED_POINT: 'on_the_way_to_assisted_point',
		ON_THE_WAY_TO_DELIVERY: 'on_the_way_to_delivery',
		ON_THE_WAY_TO_RETURN: 'on_the_way_to_return',
		ON_THE_WAY_TO_SUB_HUB: 'on_the_way_to_sub_hub',
		PARCEL_AT_ASSISTED_POINT: 'parcel_at_assisted_point',
		PARCEL_AT_SUB_HUB: 'parcel_at_sub_hub',
		PARCEL_PARKED: 'parcel_parked',
		PARCEL_PROCESS_BY_FULFILLMENT: 'parcel_process_by_fulfillment',
		PARKED_PICK_UP: 'parked_pick_up',
		PARTIAL_DELIVERED: 'partial_delivered',
		PAYMENT_DISBURSEMENT: 'payment_disbursement',
		PAYMENT_INITITATED: 'payment_inititated',
		PAYMENT_PENDING: 'payment_pending',
		PAYMENT_RECEIVE_BY_ACCOUNT: 'payment_receive_by_account',
		PAYMENT_RECEIVE_BY_BRANCH: 'payment_receive_by_branch',
		PAYMENT_RECEIVE_REQUEST_TO_ACCOUNT: 'payment_receive_request_to_account',
		PAYMENT_RECEIVE_REQUEST_TO_BRANCH: 'payment_receive_request_to_branch',
		PICK_AGENT_ASSIGNED: 'pick_agent_assigned',
		PICK_AGENT_TRANSFER: 'pick_agent_transfer',
		PICKED_UP: 'picked_up',
		PRODUCT_PICKED_UP: 'product_picked_up',
		PRODUCTS_AT_BRANCH: 'products_at_branch',
		PRODUCTS_RECEIVED_BY_FULFILMENT: 'products_received_by_fulfilment',
		PRODUCTS_SEND_TO_FULFILMENT: 'products_send_to_fulfilment',
		RECEIVED_AT_FULFILLMENT: 'received_at_fulfillment',
		RECEIVED_AT_RTN: 'received_at_rtn',
		REQUEST_INITIATED: 'request_initiated',
		RETURNED_TO_MERCHANT: 'returned_to_merchant',
		RETURNED_TO_RTN: 'returned_to_rtn',
		SEND_TO_FULFILLMENT_BRANCH_TRANSFER: 'send_to_fulfillment_branch_transfer',
		SENT_TO_DESTINATION_HUB: 'sent_to_destination_hub',
		SENT_TO_DESTINATION_HUB_RETURN: 'sent_to_destination_hub_return',
		SENT_TO_FULFILLMENT_WRONG_ROUTING: 'sent_to_fulfillment_wrong_routing',
		SLA_CROSSED_BY_ECOURIER: 'sla_crossed_by_ecourier',
		SLA_CROSSED_BY_FRANCHISE: 'sla_crossed_by_franchise',
		TRANSFER_ACCEPTED: 'transfer_accepted',
		TRANSFER_REJECTED: 'transfer_rejected',
		TRANSFER_REQUESTED: 'transfer_requested'
	},
	[PartnerType.POST_EX]: {
		UNBOOKED: 'unbooked',
		BOOKED: 'booked',
		POST_EX_WAREHOUSE: 'post_ex_ware_house',
		OUT_FOR_DELIVERY: 'out_for_delivery',
		DELIVERED: 'delivered',
		RETURNED: 'returned',
		UN_ASSIGNED_BY_ME: 'un_assigned_by_me',
		EXPIRED: 'expired',
		DELIVERY_UNDER_REVIEW: 'delivery_under_review',
		PICKED_BY_POST_EX: 'picked_by_post_ex',
		OUT_FOR_RETURN: 'out_for_return',
		ATTEMPTED: 'attempted',
		EN_ROUTE_TO_POST_EX_WAREHOUSE: 'en_route_to_post_ex_warehouse'
	},
	[PartnerType.MP_COURIER]: {
		DELIVERED: 'delivered',
		BOOKING: 'booking',
		ARRIVAL: 'arrival',
		MANIFESTED: 'manifested',
		BAGGED: 'bagged',
		LOADED: 'loaded',
		UNLOAD: 'unload',
		DEBAG: 'debag',
		DEMANIFEST: 'demanifest',
		RUNSHEET: 'runsheet',
		POD: 'pod'
	},
	[PartnerType.LEOPARDS]: {
		PICKUP_REQUEST_NOT_SEND: 'pickup_request_not_send',
		FOURTY_EIGHT_HOURS_AUTO_CANCELED: '48_hours_auto_canceled',
		PICKUP_REQUEST_SENT: 'pickup_request_sent',
		CANCELLED: 'cancelled',
		CONSIGNMENT_BOOKED: 'consignment_booked',
		ASSIGN_TO_COURIER: 'assign_to_courier',
		ARRIVED_AT_STATION: 'arrived_at_station',
		RETURNED_TO_SHIPPER: 'returned_to_shipper',
		MISSROUTE: 'missroute',
		PENDING: 'pending',
		DELIVERED: 'delivered',
		DISPATCHED: 'dispatched',
		READY_FOR_RETURN: 'ready_for_return',
		BEING_RETURN: 'being_return',
		SHIPMENT_PICKED: 'shipment_picked',
		DROP_OFF_AT_EXPRESS_CENTER: 'drop_off_at_express_center'
	},
	[PartnerType.BLUE_EX]: {
		BOOKED: 'booked',
		READY_FOR_PICKUP: 'ready_for_pickup',
		ARRIVAL: 'arrival',
		IN_TRANSIT: 'in_transit',
		DELIVERED: 'delivered',
		RETURNED: 'returned'
	},
	[PartnerType.MOVEX]: {
		PENDING: 'pending',
		RECEIVED_BY_WAREHOUSE: 'received_by_warehouse',
		DELIVERED: 'delivered',
		CANCELLED: 'cancelled',
		DELETED: 'deleted'
	},
	[PartnerType.PAPERFLY]: {
		UNDEFINED: 'undefined',
		NOT_YET_PICKED: 'Not yet picked',
		IN_TRANSIT: 'In transit',
		ON_THE_WAY_TO_POINT: 'On the way to point',
		REACHED_AT_DELIVERY_POINT: 'Reached at delivery point',
		ON_THE_WAY_TO_DELIVERY: 'On the way to delivery',
		ON_HOLD: 'On hold',
		PARTIALLY_DELIVERED: 'Partially delivered',
		EXCHANGED: 'Exchanged',
		DELIVERED: 'Delivered',
		RETURN: 'Return',
		RETURNED_TO_MERCHANT: 'Returned to merchant'
	},
	[PartnerType.PANDAGO]: {
		UNDEFINED: 'undefined',
		NEW: 'NEW',
		RECEIVED: 'RECEIVED',
		WAITING_FOR_TRANSPORT: 'WAITING_FOR_TRANSPORT',
		ASSIGNED_TO_TRANSPORT: 'ASSIGNED_TO_TRANSPORT',
		COURIER_ACCEPTED_DELIVERY: 'COURIER_ACCEPTED_DELIVERY',
		NEAR_VENDOR: 'NEAR_VENDOR',
		PICKED_UP: 'PICKED_UP',
		COURIER_LEFT_VENDOR: 'COURIER_LEFT_VENDOR',
		NEAR_CUSTOMER: 'NEAR_CUSTOMER',
		DELIVERED: 'DELIVERED',
		DELAYED: 'DELAYED',
		CANCELLED: 'CANCELLED',
		RETURNED_TO_VENDOR: 'RETURNED_TO_VENDOR'
	},
	[PartnerType.XPRESS_IN_TOWN]: {
		UNDEFINED: 'undefined',
		PENDING: 'Pending',
		ACCEPTED: 'Accepted',
		AT_SORTING: 'At Sorting',
		IN_TRANSIT: 'In Transit',
		ASSIGN_TO: 'Assign To',
		DELIVERED: 'Delivered',
		PARTIAL_DELIVERED: 'Partial Delivered',
		EXCHANGED: 'Exchanged',
		ON_HOLD: 'On Hold',
		RESCHEDULED: 'Rescheduled',
		RETURNED: 'Returned',
		RETURNED_FROM_HUB: 'Returned From Hub'
	}
}

export const deliveryOrderStatusOptions = {
	[PartnerType.STEADFAST]: [
		{
			label: 'Undefined',
			value: deliveryOrderStatuses[PartnerType.STEADFAST].UNDEFINED
		},
		{
			label: 'Pending',
			value: deliveryOrderStatuses[PartnerType.STEADFAST].PENDING
		},
		{
			label: 'Approval Pending',
			value: deliveryOrderStatuses[PartnerType.STEADFAST].APPROVAL_PENDING
		},
		{
			label: 'In Review',
			value: deliveryOrderStatuses[PartnerType.STEADFAST].IN_REVIEW
		},
		{
			label: 'Hold',
			value: deliveryOrderStatuses[PartnerType.STEADFAST].HOLD
		},
		{
			label: 'Unknown',
			value: deliveryOrderStatuses[PartnerType.STEADFAST].UNKNOWN
		},
		{
			label: 'Cancelled',
			value: deliveryOrderStatuses[PartnerType.STEADFAST].CANCELLED
		},
		{
			label: 'Delivered',
			value: deliveryOrderStatuses[PartnerType.STEADFAST].DELIVERED
		},
		{
			label: 'Partly Delivered',
			value: deliveryOrderStatuses[PartnerType.STEADFAST].PARTLY_DELIVERED
		},
		{
			label: 'Partial Delivered',
			value: deliveryOrderStatuses[PartnerType.STEADFAST].PARTIAL_DELIVERED
		}
	],
	[PartnerType.PATHAO]: [
		{
			label: 'Undefined',
			value: deliveryOrderStatuses[PartnerType.PATHAO].UNDEFINED
		},
		{
			label: 'Pickup Requested',
			value: deliveryOrderStatuses[PartnerType.PATHAO].PICKUP_REQUESTED
		},
		{
			label: 'Assigned For Pickup',
			value: deliveryOrderStatuses[PartnerType.PATHAO].ASSIGNED_FOR_PICKUP
		},
		{
			label: 'Picked',
			value: deliveryOrderStatuses[PartnerType.PATHAO].PICKED
		},
		{
			label: 'Pickup Failed',
			value: deliveryOrderStatuses[PartnerType.PATHAO].PICKUP_FAILED
		},
		{
			label: 'Pickup Cancelled',
			value: deliveryOrderStatuses[PartnerType.PATHAO].PICKUP_CANCELLED
		},
		{
			label: 'At The Sorting Hub',
			value: deliveryOrderStatuses[PartnerType.PATHAO].AT_THE_SORTING_HUB
		},
		{
			label: 'In Transit',
			value: deliveryOrderStatuses[PartnerType.PATHAO].IN_TRANSIT
		},
		{
			label: 'Received At Last Mile Hub',
			value: deliveryOrderStatuses[PartnerType.PATHAO].RECEIVED_AT_LAST_MILE_HUB
		},
		{
			label: 'Assigned For Delivery',
			value: deliveryOrderStatuses[PartnerType.PATHAO].ASSIGNED_FOR_DELIVERY
		},
		{
			label: 'Delivered',
			value: deliveryOrderStatuses[PartnerType.PATHAO].DELIVERED
		},
		{
			label: 'Partial Delivery',
			value: deliveryOrderStatuses[PartnerType.PATHAO].PARTIAL_DELIVERY
		},
		{
			label: 'Return',
			value: deliveryOrderStatuses[PartnerType.PATHAO].RETURN
		},
		{
			label: 'Delivery Failed',
			value: deliveryOrderStatuses[PartnerType.PATHAO].DELIVERY_FAILED
		},
		{
			label: 'On Hold',
			value: deliveryOrderStatuses[PartnerType.PATHAO].ON_HOLD
		},
		{
			label: 'Payment Invoice',
			value: deliveryOrderStatuses[PartnerType.PATHAO].PAYMENT_INVOICE
		},
		{
			label: 'Paid Return',
			value: deliveryOrderStatuses[PartnerType.PATHAO].PAID_RETURN
		},
		{
			label: 'Exchange',
			value: deliveryOrderStatuses[PartnerType.PATHAO].EXCHANGE
		},
		{
			label: 'Order Created',
			value: deliveryOrderStatuses[PartnerType.PATHAO].ORDER_CREATED
		},
		{
			label: 'Order Updated',
			value: deliveryOrderStatuses[PartnerType.PATHAO].ORDER_UPDATED
		}
	],
	[PartnerType.PIDEX]: [
		{
			label: 'Undefined',
			value: deliveryOrderStatuses[PartnerType.PIDEX].UNDEFINED
		},
		{
			label: 'Out For Delivery',
			value: deliveryOrderStatuses[PartnerType.PIDEX].OUT_FOR_DELIVERY
		},
		{
			label: 'Returned',
			value: deliveryOrderStatuses[PartnerType.PIDEX].RETURNED
		},
		{
			label: 'Delivered',
			value: deliveryOrderStatuses[PartnerType.PIDEX].DELIVERED
		},
		{
			label: 'Order Placed',
			value: deliveryOrderStatuses[PartnerType.PIDEX].ORDER_PLACED
		},
		{
			label: 'Pick Up Requested',
			value: deliveryOrderStatuses[PartnerType.PIDEX].PICK_UP_REQUESTED
		},
		{
			label: 'Picked Up',
			value: deliveryOrderStatuses[PartnerType.PIDEX].PICKED_UP
		},
		{
			label: 'Pickup Cancelled',
			value: deliveryOrderStatuses[PartnerType.PIDEX].PICKUP_CANCELLED
		},
		{
			label: 'In Transit',
			value: deliveryOrderStatuses[PartnerType.PIDEX].IN_TRANSIT
		},
		{
			label: 'Return In Progress',
			value: deliveryOrderStatuses[PartnerType.PIDEX].RETURN_IN_PROGRESS
		}
	],
	[PartnerType.REDX]: [
		{
			label: 'Undefined',
			value: deliveryOrderStatuses[PartnerType.REDX].UNDEFINED
		},
		{
			label: 'Order Placed',
			value: deliveryOrderStatuses[PartnerType.REDX].ORDER_PLACED
		},
		{
			label: 'Delivered',
			value: deliveryOrderStatuses[PartnerType.REDX].DELIVERED
		},
		{
			label: 'Deleted',
			value: deliveryOrderStatuses[PartnerType.REDX].DELETED
		},
		{
			label: 'Returning',
			value: deliveryOrderStatuses[PartnerType.REDX].RETURNING
		},
		{
			label: 'Damaged',
			value: deliveryOrderStatuses[PartnerType.REDX].DAMAGED
		},
		{
			label: 'Delivery In Progress',
			value: deliveryOrderStatuses[PartnerType.REDX].DELIVERY_IN_PROGRESS
		},
		{
			label: 'Returned',
			value: deliveryOrderStatuses[PartnerType.REDX].RETURNED
		},
		{
			label: 'Settled',
			value: deliveryOrderStatuses[PartnerType.REDX].SETTLED
		},
		{
			label: 'Rejected',
			value: deliveryOrderStatuses[PartnerType.REDX].REJECTED
		},
		{
			label: 'Picked Up',
			value: deliveryOrderStatuses[PartnerType.REDX].PICKED_UP
		},
		{
			label: 'Pickup Pending',
			value: deliveryOrderStatuses[PartnerType.REDX].PICKUP_PENDING
		},
		{
			label: 'Hold',
			value: deliveryOrderStatuses[PartnerType.REDX].ON_HOLD
		},
		{
			label: 'Pickup Blocked',
			value: deliveryOrderStatuses[PartnerType.REDX].PICKUP_HOLD
		},
		{
			label: 'Reattempt Pending',
			value: deliveryOrderStatuses[PartnerType.REDX].SHOPUP_RETURNING_APPROVE_PENDING
		}
	],
	[PartnerType.E_COURIER]: [
		{
			label: 'Undefined',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].UNDEFINED
		},
		{
			label: 'Order Placed',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].ORDER_PLACED
		},
		{
			label: 'Agent Assign To Receive Cancel Parcel From Assisted Point',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].AGENT_ASSIGN_TO_RECEIVE_CANCEL_PARCEL_FROM_ASSISTED_POINT
		},
		{
			label: 'Assign Agent Cancelled',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].ASSIGN_AGENT_CANCELLED
		},
		{
			label: 'Assign Branch Cancelled',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].ASSIGN_BRANCH_CANCELLED
		},
		{
			label: 'At Fulfillment Wrong Routing',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].AT_FULFILLMENT_WRONG_ROUTING
		},
		{
			label: 'At Fulfillment Branch Transfer',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].AT_FULFILLMENT_BRANCH_TRANSFER
		},
		{
			label: 'Branch Assigned',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].BRANCH_ASSIGNED
		},
		{
			label: 'Cancel By Agent',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].CANCEL_BY_AGENT
		},
		{
			label: 'Cancel By Assisted Point',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].CANCEL_BY_ASSISTED_POINT
		},
		{
			label: 'Cancel By Sub Hub Agent',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].CANCEL_BY_SUB_HUB_AGENT
		},
		{
			label: 'Cancel Received At Hub',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].CANCEL_RECEIVED_AT_HUB
		},
		{
			label: 'Cancel Received At Sub Hub',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].CANCEL_RECEIVED_AT_SUB_HUB
		},
		{
			label: 'Cancel Received From Assisted Point',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].CANCEL_RECEIVED_FROM_ASSISTED_POINT
		},
		{
			label: 'Cancel Received From Sub Hub',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].CANCEL_RECEIVED_FROM_SUB_HUB
		},
		{
			label: 'Damaged Or Lost By Ecourier',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].DAMAGED_OR_LOST_BY_ECOURIER
		},
		{
			label: 'Damaged Or Lost By Franchise',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].DAMAGED_OR_LOST_BY_FRANCHISE
		},
		{
			label: 'Deleted',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].DELETED
		},
		{
			label: 'Delivered',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].DELIVERED
		},
		{
			label: 'Delivery Agent Assigned',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].DELIVERY_AGENT_ASSIGNED
		},
		{
			label: 'Delivery Agent Assigned Return',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].DELIVERY_AGENT_ASSIGNED_RETURN
		},
		{
			label: 'Delivery Agent Assigned To Assisted Point',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].DELIVERY_AGENT_ASSIGNED_TO_ASSISTED_POINT
		},
		{
			label: 'Delivery Agent Assigned To Sub Hub',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].DELIVERY_AGENT_ASSIGNED_TO_SUB_HUB
		},
		{
			label: 'Exchange Delivery',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].EXCHANGE_DELIVERY
		},
		{
			label: 'Hold At Fulfillment',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].HOLD_AT_FULFILLMENT
		},
		{
			label: 'Hold At Hub',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].HOLD_AT_HUB
		},
		{
			label: 'Hold At Hub Branch Transfer',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].HOLD_AT_HUB_BRANCH_TRANSFER
		},
		{
			label: 'Hold At Hub Return',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].HOLD_AT_HUB_RETURN
		},
		{
			label: 'Hold At Hub Wrong Routing',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].HOLD_AT_HUB_WRONG_ROUTING
		},
		{
			label: 'Hold At Hub On Merchant Instruction',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].HOLD_AT_HUB_ON_MERCHANT_INSTRUCTION
		},
		{
			label: 'Hold By Agent',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].HOLD_BY_AGENT
		},
		{
			label: 'Hold By Agent Return',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].HOLD_BY_AGENT_RETURN
		},
		{
			label: 'Hold Initiated',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].HOLD_INITIATED
		},
		{
			label: 'In Destination Hub',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].IN_DESTINATION_HUB
		},
		{
			label: 'In Destination Hub Return',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].IN_DESTINATION_HUB_RETURN
		},
		{
			label: 'Initiated',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].INITIATED
		},
		{
			label: 'Initiated Api',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].INITIATED_API
		},
		{
			label: 'On The Way To Assisted Point',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].ON_THE_WAY_TO_ASSISTED_POINT
		},
		{
			label: 'On The Way To Delivery',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].ON_THE_WAY_TO_DELIVERY
		},
		{
			label: 'On The Way To Return',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].ON_THE_WAY_TO_RETURN
		},
		{
			label: 'On The Way To Sub Hub',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].ON_THE_WAY_TO_SUB_HUB
		},
		{
			label: 'Parcel At Assisted Point',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].PARCEL_AT_ASSISTED_POINT
		},
		{
			label: 'Parcel At Sub Hub',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].PARCEL_AT_SUB_HUB
		},
		{
			label: 'Parcel Parked',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].PARCEL_PARKED
		},
		{
			label: 'Parcel Process By Fulfillment',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].PARCEL_PROCESS_BY_FULFILLMENT
		},
		{
			label: 'Parked Pick Up',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].PARKED_PICK_UP
		},
		{
			label: 'Partial Delivered',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].PARTIAL_DELIVERED
		},
		{
			label: 'Payment Disbursement',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].PAYMENT_DISBURSEMENT
		},
		{
			label: 'Payment Inititated',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].PAYMENT_INITITATED
		},
		{
			label: 'Payment Pending',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].PAYMENT_PENDING
		},
		{
			label: 'Payment Receive By Account',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].PAYMENT_RECEIVE_BY_ACCOUNT
		},
		{
			label: 'Payment Receive By Branch',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].PAYMENT_RECEIVE_BY_BRANCH
		},
		{
			label: 'Payment Receive Request To Account',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].PAYMENT_RECEIVE_REQUEST_TO_ACCOUNT
		},
		{
			label: 'Payment Receive Request To Branch',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].PAYMENT_RECEIVE_REQUEST_TO_BRANCH
		},
		{
			label: 'Pick Agent Assigned',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].PICK_AGENT_ASSIGNED
		},
		{
			label: 'Pick Agent Transfer',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].PICK_AGENT_TRANSFER
		},
		{
			label: 'Picked Up',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].PICKED_UP
		},
		{
			label: 'Product Picked Up',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].PRODUCT_PICKED_UP
		},
		{
			label: 'Products At Branch',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].PRODUCTS_AT_BRANCH
		},
		{
			label: 'Products Received By Fulfilment',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].PRODUCTS_RECEIVED_BY_FULFILMENT
		},
		{
			label: 'Products Send To Fulfilment',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].PRODUCTS_SEND_TO_FULFILMENT
		},
		{
			label: 'Received At Fulfillment',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].RECEIVED_AT_FULFILLMENT
		},
		{
			label: 'Received At Rtn',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].RECEIVED_AT_RTN
		},
		{
			label: 'Request Initiated',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].REQUEST_INITIATED
		},
		{
			label: 'Returned To Merchant',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].RETURNED_TO_MERCHANT
		},
		{
			label: 'Returned To Rtn',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].RETURNED_TO_RTN
		},
		{
			label: 'Send To Fulfillment Branch Transfer',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].SEND_TO_FULFILLMENT_BRANCH_TRANSFER
		},
		{
			label: 'Sent To Destination Hub',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].SENT_TO_DESTINATION_HUB
		},
		{
			label: 'Sent To Destination Hub Return',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].SENT_TO_DESTINATION_HUB_RETURN
		},
		{
			label: 'Sent To Fulfillment Wrong Routing',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].SENT_TO_FULFILLMENT_WRONG_ROUTING
		},
		{
			label: 'Sla Crossed By Ecourier',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].SLA_CROSSED_BY_ECOURIER
		},
		{
			label: 'Sla Crossed By Franchise',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].SLA_CROSSED_BY_FRANCHISE
		},
		{
			label: 'Transfer Accepted',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].TRANSFER_ACCEPTED
		},
		{
			label: 'Transfer Rejected',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].TRANSFER_REJECTED
		},
		{
			label: 'Transfer Requested',
			value: deliveryOrderStatuses[PartnerType.E_COURIER].TRANSFER_REQUESTED
		}
	],
	[PartnerType.POST_EX]: [
		{
			label: 'Unbooked',
			value: deliveryOrderStatuses[PartnerType.POST_EX].UNBOOKED
		},
		{
			label: 'Booked',
			value: deliveryOrderStatuses[PartnerType.POST_EX].BOOKED
		},
		{
			label: 'PostEx WareHouse',
			value: deliveryOrderStatuses[PartnerType.POST_EX].POST_EX_WAREHOUSE
		},
		{
			label: 'Out For Delivery',
			value: deliveryOrderStatuses[PartnerType.POST_EX].OUT_FOR_DELIVERY
		},
		{
			label: 'Delivered',
			value: deliveryOrderStatuses[PartnerType.POST_EX].DELIVERED
		},
		{
			label: 'Returned',
			value: deliveryOrderStatuses[PartnerType.POST_EX].RETURNED
		},
		{
			label: 'Un-Assigned By Me',
			value: deliveryOrderStatuses[PartnerType.POST_EX].UN_ASSIGNED_BY_ME
		},
		{
			label: 'Expired',
			value: deliveryOrderStatuses[PartnerType.POST_EX].EXPIRED
		},
		{
			label: 'Delivery Under Review',
			value: deliveryOrderStatuses[PartnerType.POST_EX].DELIVERY_UNDER_REVIEW
		},
		{
			label: 'Picked By PostEx',
			value: deliveryOrderStatuses[PartnerType.POST_EX].PICKED_BY_POST_EX
		},
		{
			label: 'Out For Return',
			value: deliveryOrderStatuses[PartnerType.POST_EX].OUT_FOR_RETURN
		},
		{
			label: 'Attempted',
			value: deliveryOrderStatuses[PartnerType.POST_EX].ATTEMPTED
		},
		{
			label: 'En-Route to PostEx warehouse',
			value: deliveryOrderStatuses[PartnerType.POST_EX].EN_ROUTE_TO_POST_EX_WAREHOUSE
		}
	],
	[PartnerType.MP_COURIER]: [
		{
			label: 'Delivered',
			value: deliveryOrderStatuses[PartnerType.MP_COURIER].DELIVERED
		},
		{
			label: 'Booking',
			value: deliveryOrderStatuses[PartnerType.MP_COURIER].BOOKING
		},
		{
			label: 'Arrival',
			value: deliveryOrderStatuses[PartnerType.MP_COURIER].ARRIVAL
		},
		{
			label: 'Manifested',
			value: deliveryOrderStatuses[PartnerType.MP_COURIER].MANIFESTED
		},
		{
			label: 'Bagged',
			value: deliveryOrderStatuses[PartnerType.MP_COURIER].BAGGED
		},
		{
			label: 'Loaded',
			value: deliveryOrderStatuses[PartnerType.MP_COURIER].LOADED
		},
		{
			label: 'Unload',
			value: deliveryOrderStatuses[PartnerType.MP_COURIER].UNLOAD
		},
		{
			label: 'Debag',
			value: deliveryOrderStatuses[PartnerType.MP_COURIER].DEBAG
		},
		{
			label: 'Demanifest',
			value: deliveryOrderStatuses[PartnerType.MP_COURIER].DEMANIFEST
		},
		{
			label: 'Runsheet',
			value: deliveryOrderStatuses[PartnerType.MP_COURIER].RUNSHEET
		},
		{
			label: 'Pod',
			value: deliveryOrderStatuses[PartnerType.MP_COURIER].POD
		}
	],
	[PartnerType.LEOPARDS]: [
		{
			label: 'Pickup Request not Send',
			value: deliveryOrderStatuses[PartnerType.LEOPARDS].PICKUP_REQUEST_NOT_SEND
		},
		{
			label: '48 Hours Auto Canceled',
			value: deliveryOrderStatuses[PartnerType.LEOPARDS].FOURTY_EIGHT_HOURS_AUTO_CANCELED
		},
		{
			label: 'Pickup Request Sent',
			value: deliveryOrderStatuses[PartnerType.LEOPARDS].PICKUP_REQUEST_SENT
		},
		{
			label: 'Cancelled',
			value: deliveryOrderStatuses[PartnerType.LEOPARDS].CANCELLED
		},
		{
			label: 'Consignment Booked',
			value: deliveryOrderStatuses[PartnerType.LEOPARDS].CONSIGNMENT_BOOKED
		},
		{
			label: 'Assign to Courier',
			value: deliveryOrderStatuses[PartnerType.LEOPARDS].ASSIGN_TO_COURIER
		},
		{
			label: 'Arrived at Station',
			value: deliveryOrderStatuses[PartnerType.LEOPARDS].ARRIVED_AT_STATION
		},
		{
			label: 'Returned to shipper',
			value: deliveryOrderStatuses[PartnerType.LEOPARDS].RETURNED_TO_SHIPPER
		},
		{
			label: 'Missroute',
			value: deliveryOrderStatuses[PartnerType.LEOPARDS].MISSROUTE
		},
		{
			label: 'Pending',
			value: deliveryOrderStatuses[PartnerType.LEOPARDS].PENDING
		},
		{
			label: 'Delivered',
			value: deliveryOrderStatuses[PartnerType.LEOPARDS].DELIVERED
		},
		{
			label: 'Dispatched',
			value: deliveryOrderStatuses[PartnerType.LEOPARDS].DISPATCHED
		},
		{
			label: 'Ready for Return',
			value: deliveryOrderStatuses[PartnerType.LEOPARDS].READY_FOR_RETURN
		},
		{
			label: 'Being Return',
			value: deliveryOrderStatuses[PartnerType.LEOPARDS].BEING_RETURN
		},
		{
			label: 'Shipment Picked',
			value: deliveryOrderStatuses[PartnerType.LEOPARDS].SHIPMENT_PICKED
		},
		{
			label: 'Drop Off at Express Center',
			value: deliveryOrderStatuses[PartnerType.LEOPARDS].DROP_OFF_AT_EXPRESS_CENTER
		}
	],
	[PartnerType.BLUE_EX]: [
		{
			label: 'Booked',
			value: deliveryOrderStatuses[PartnerType.BLUE_EX].BOOKED
		},
		{
			label: 'Ready for pickup',
			value: deliveryOrderStatuses[PartnerType.BLUE_EX].READY_FOR_PICKUP
		},
		{
			label: 'Arrival',
			value: deliveryOrderStatuses[PartnerType.BLUE_EX].ARRIVAL
		},
		{
			label: 'In-transit',
			value: deliveryOrderStatuses[PartnerType.BLUE_EX].IN_TRANSIT
		},
		{
			label: 'Delivered',
			value: deliveryOrderStatuses[PartnerType.BLUE_EX].DELIVERED
		},
		{
			label: 'Returned',
			value: deliveryOrderStatuses[PartnerType.BLUE_EX].RETURNED
		}
	],
	[PartnerType.MOVEX]: [
		{
			label: 'Pending',
			value: deliveryOrderStatuses[PartnerType.MOVEX].PENDING
		},
		{
			label: 'Received By Warehouse',
			value: deliveryOrderStatuses[PartnerType.MOVEX].RECEIVED_BY_WAREHOUSE
		},
		{
			label: 'Delivered',
			value: deliveryOrderStatuses[PartnerType.MOVEX].DELIVERED
		},
		{
			label: 'Cancelled',
			value: deliveryOrderStatuses[PartnerType.MOVEX].CANCELLED
		},
		{
			label: 'Deleted',
			value: deliveryOrderStatuses[PartnerType.MOVEX].DELETED
		}
	],
	[PartnerType.PAPERFLY]: [
		{
			label: 'Undefined',
			value: deliveryOrderStatuses[PartnerType.PAPERFLY].UNDEFINED
		},
		{
			label: 'Not Yet Picked',
			value: deliveryOrderStatuses[PartnerType.PAPERFLY].NOT_YET_PICKED
		},
		{
			label: 'In Transit',
			value: deliveryOrderStatuses[PartnerType.PAPERFLY].IN_TRANSIT
		},
		{
			label: 'On The Way To Point',
			value: deliveryOrderStatuses[PartnerType.PAPERFLY].ON_THE_WAY_TO_POINT
		},
		{
			label: 'Reached At Delivery Point',
			value: deliveryOrderStatuses[PartnerType.PAPERFLY].REACHED_AT_DELIVERY_POINT
		},
		{
			label: 'On The Way To Delivery',
			value: deliveryOrderStatuses[PartnerType.PAPERFLY].ON_THE_WAY_TO_DELIVERY
		},
		{
			label: 'On Hold',
			value: deliveryOrderStatuses[PartnerType.PAPERFLY].ON_HOLD
		},
		{
			label: 'Partially Delivered',
			value: deliveryOrderStatuses[PartnerType.PAPERFLY].PARTIALLY_DELIVERED
		},
		{
			label: 'Exchanged',
			value: deliveryOrderStatuses[PartnerType.PAPERFLY].EXCHANGED
		},
		{
			label: 'Delivered',
			value: deliveryOrderStatuses[PartnerType.PAPERFLY].DELIVERED
		},
		{
			label: 'Return',
			value: deliveryOrderStatuses[PartnerType.PAPERFLY].RETURN
		},
		{
			label: 'Returned To Merchant',
			value: deliveryOrderStatuses[PartnerType.PAPERFLY].RETURNED_TO_MERCHANT
		}
	],
	[PartnerType.PANDAGO]: [
		{
			label: 'Undefined',
			value: deliveryOrderStatuses[PartnerType.PANDAGO].UNDEFINED
		},
		{
			label: 'New',
			value: deliveryOrderStatuses[PartnerType.PANDAGO].NEW
		},
		{
			label: 'Received',
			value: deliveryOrderStatuses[PartnerType.PANDAGO].RECEIVED
		},
		{
			label: 'Waiting For Transport',
			value: deliveryOrderStatuses[PartnerType.PANDAGO].WAITING_FOR_TRANSPORT
		},
		{
			label: 'Assigned To Transport',
			value: deliveryOrderStatuses[PartnerType.PANDAGO].ASSIGNED_TO_TRANSPORT
		},
		{
			label: 'Courier Accepted Delivery',
			value: deliveryOrderStatuses[PartnerType.PANDAGO].COURIER_ACCEPTED_DELIVERY
		},
		{
			label: 'Near Vendor',
			value: deliveryOrderStatuses[PartnerType.PANDAGO].NEAR_VENDOR
		},
		{
			label: 'Picked Up',
			value: deliveryOrderStatuses[PartnerType.PANDAGO].PICKED_UP
		},
		{
			label: 'Courier Left Vendor',
			value: deliveryOrderStatuses[PartnerType.PANDAGO].COURIER_LEFT_VENDOR
		},
		{
			label: 'Near Customer',
			value: deliveryOrderStatuses[PartnerType.PANDAGO].NEAR_CUSTOMER
		},
		{
			label: 'Delivered',
			value: deliveryOrderStatuses[PartnerType.PANDAGO].DELIVERED
		},
		{
			label: 'Delayed',
			value: deliveryOrderStatuses[PartnerType.PANDAGO].DELAYED
		},
		{
			label: 'Cancelled',
			value: deliveryOrderStatuses[PartnerType.PANDAGO].CANCELLED
		},
		{
			label: 'Returned To Vendor',
			value: deliveryOrderStatuses[PartnerType.PANDAGO].RETURNED_TO_VENDOR
		}
	],
	[PartnerType.XPRESS_IN_TOWN]: [
		{
			label: 'Undefined',
			value: deliveryOrderStatuses[PartnerType.XPRESS_IN_TOWN].UNDEFINED
		},
		{
			label: 'Pending',
			value: deliveryOrderStatuses[PartnerType.XPRESS_IN_TOWN].PENDING
		},
		{
			label: 'Accepted',
			value: deliveryOrderStatuses[PartnerType.XPRESS_IN_TOWN].ACCEPTED
		},
		{
			label: 'At Sorting',
			value: deliveryOrderStatuses[PartnerType.XPRESS_IN_TOWN].AT_SORTING
		},
		{
			label: 'In Transit',
			value: deliveryOrderStatuses[PartnerType.XPRESS_IN_TOWN].IN_TRANSIT
		},
		{
			label: 'Assign To',
			value: deliveryOrderStatuses[PartnerType.XPRESS_IN_TOWN].ASSIGN_TO
		},
		{
			label: 'Delivered',
			value: deliveryOrderStatuses[PartnerType.XPRESS_IN_TOWN].DELIVERED
		},
		{
			label: 'Partial Delivered',
			value: deliveryOrderStatuses[PartnerType.XPRESS_IN_TOWN].PARTIAL_DELIVERED
		},
		{
			label: 'Exchanged',
			value: deliveryOrderStatuses[PartnerType.XPRESS_IN_TOWN].EXCHANGED
		},
		{
			label: 'On Hold',
			value: deliveryOrderStatuses[PartnerType.XPRESS_IN_TOWN].ON_HOLD
		},
		{
			label: 'Rescheduled',
			value: deliveryOrderStatuses[PartnerType.XPRESS_IN_TOWN].RESCHEDULED
		},
		{
			label: 'Returned',
			value: deliveryOrderStatuses[PartnerType.XPRESS_IN_TOWN].RETURNED
		},
		{
			label: 'Returned From Hub',
			value: deliveryOrderStatuses[PartnerType.XPRESS_IN_TOWN].RETURNED_FROM_HUB
		}
	]
}

export const customerTypeOptions = [
	{
		value: customerTypes.DISTRIBUTOR,
		label: 'Distributor'
	},
	{
		value: customerTypes.DEALER,
		label: 'Dealer'
	},
	{
		value: customerTypes.RETAILER,
		label: 'Retailer'
	},
	{
		value: customerTypes.E_COMMERCE_CUSTOMER,
		label: 'E-Commerce Customer'
	},
	{
		value: customerTypes.TRADER,
		label: 'Trader'
	},
	{
		value: customerTypes.MICRO_ENTREPRENEUR,
		label: 'Micro Entrepreneur'
	},
	{
		value: customerTypes.CORPORATE,
		label: 'Corporate'
	}
]

export const LoadState = {
	SUCCEEDED: 'SUCCEEDED',
	LOADING: 'LOADING',
	FAILED: 'FAILED',
	NONE: 'NONE'
}

export const industryGroup = {
	E_COMMERCE: 'E_COMMERCE',
	ENTERPRISE: 'ENTERPRISE'
}

export const industries = {
	GENERAL: 'GENERAL',
	STEEL: 'STEEL',
	AGRICULTURE: 'AGRICULTURE',
	FMCG: 'FMCG',
	ELECTRONICS: 'ELECTRONICS',
	CONSTRUCTION: 'CONSTRUCTION',
	PHARMACEUTICALS: 'PHARMACEUTICALS',
	METAL_STEEL: 'METAL_STEEL',
	E_COMMERCE: 'E_COMMERCE',
	CASH_DISTRIBUTION: 'CASH_DISTRIBUTION',
	MANUFACTURING_DISTRIBUTION: 'MANUFACTURING_DISTRIBUTION',
	REAL_ESTATE_BUILDING_SUPPLIES: 'REAL_ESTATE_BUILDING_SUPPLIES'
}

export const ecommerceIndustryType = {
	FASHION: 'FASHION',
	BEAUTY: 'BEAUTY',
	FOOD_BEVERAGE: 'FOOD_BEVERAGE',
	AGRICULTURE: 'AGRICULTURE',
	ELECTRONICS: 'ELECTRONICS',
	HEALTH_CARE: 'HEALTH_CARE',
	EDUCATION: 'EDUCATION',
	OTHER: 'OTHER'
}

export const ecommerceIndustryTypeOptions = [
	{
		label: 'Fashion',
		value: ecommerceIndustryType.FASHION,
		logoUrl: '/img/fashion-ecommerce-logo.svg'
	},
	{
		label: 'Beauty',
		value: ecommerceIndustryType.BEAUTY,
		logoUrl: '/img/beauty-ecommerce-logo.svg'
	},
	{
		label: 'Food & Beverage',
		value: ecommerceIndustryType.FOOD_BEVERAGE,
		logoUrl: '/img/food-ecommerce-logo.svg'
	},
	{
		label: 'Books',
		value: ecommerceIndustryType.EDUCATION,
		logoUrl: '/img/education-ecommerce-logo.svg'
	},
	{
		label: 'Agro',
		value: ecommerceIndustryType.AGRICULTURE,
		logoUrl: '/img/agriculture-ecommerce-logo.svg'
	},
	{
		label: 'Electronics',
		value: ecommerceIndustryType.ELECTRONICS,
		logoUrl: '/img/electronics-ecommerce-logo.svg'
	},
	{
		label: 'Healthcare',
		value: ecommerceIndustryType.HEALTH_CARE,
		logoUrl: '/img/healthcare-ecommerce-logo.svg'
	},
	{
		label: 'Others',
		value: ecommerceIndustryType.OTHER,
		logoUrl: '/img/other-ecommerce-logo.svg'
	}
]

export const deliveryBoundaries = {
	INSIDE: 'INSIDE',
	OUTSIDE: 'OUTSIDE',
	SUBURB: 'SUBURB'
}

export const agriculturePaymentMethods = [
	{
		value: 'cash',
		label: 'Cash'
	},
	{
		value: 'credit',
		label: 'Credit'
	},
	{
		value: 'cash_on_delivery',
		label: 'Cash On Delivery'
	},
	{
		value: 'mobile_financial_service',
		label: 'Mobile Financial Service'
	}
]

export const paymentMethods = [
	{
		value: 'cash',
		label: 'Cash'
	},
	{
		value: 'cash_on_delivery',
		label: 'Cash On Delivery (COD)'
	},
	{
		value: 'check',
		label: 'Check'
	},
	{
		value: 'credit',
		label: 'Credit'
	},
	{
		value: 'card',
		label: 'Card'
	},
	{
		value: 'net_banking',
		label: 'Net Banking'
	},
	{
		value: 'bkash',
		label: 'Bkash'
	},
	{
		value: 'rocket',
		label: 'Rocket'
	},
	{
		value: 'nagad',
		label: 'Nagad'
	},
	{
		value: 'upay',
		label: 'Upay'
	},
	{
		value: 'Tap',
		label: 'tap'
	}
]

export const sources = [
	{
		value: 'PHONE_CALL',
		label: 'Phone Call',
		icon: '/img/source_phone.svg'
	},
	{
		value: 'FACEBOOK',
		label: 'Facebook',
		icon: '/img/source_facebook.svg'
	},
	{
		value: 'MESSENGER',
		label: 'Messenger',
		icon: '/img/source_messenger.svg'
	},
	{
		value: 'WHATSAPP',
		label: 'Whatsapp',
		icon: '/img/source_whatsapp.svg'
	},
	{
		value: 'INSTAGRAM',
		label: 'Instagram',
		icon: '/img/source_instagram.svg'
	},
	{
		value: 'TIKTOK',
		label: 'TikTok',
		icon: '/img/source_tiktok.svg'
	},
	{
		value: 'OFFLINE',
		label: 'Offline',
		icon: '/img/source_offline.svg'
	},
	{
		value: 'UP_SELL',
		label: 'Up Sell',
		icon: '/img/source_upsell.svg'
	},
	{
		value: 'WEBSITE',
		label: 'Website',
		icon: '/img/source_website.svg'
	},
	{
		value: 'LIVE_CHAT',
		label: 'Live Chat',
		icon: '/img/source_live_chat.svg'
	},
	{
		value: 'TELESALES',
		label: 'Telesales',
		icon: '/img/source_telesales.svg'
	},
	{
		value: 'OTHER',
		label: 'Other',
		icon: '/img/source_other.svg'
	}
]

export const orderSourceColors = {
	[orderSources.FACEBOOK]: '#3975EA',
	[orderSources.OFFLINE]: '#0F3E49',
	[orderSources.OTHER]: '#19B0DA',
	[orderSources.PHONE_CALL]: '#288EA5',
	[orderSources.WEBSITE]: '#22E6C2',
	[orderSources.UP_SELL]: '#8075F9',
	[orderSources.MESSENGER]: '#C13ED2',
	[orderSources.WHATSAPP]: '#25D366',
	[orderSources.INSTAGRAM]: '#FF6541',
	[orderSources.TIKTOK]: '#00F2EA',
	[orderSources.LIVE_CHAT]: '#FFA500',
	[orderSources.TELESALES]: '#6A5ACD'
}

// Max weight for an order.
export const maxOrderWeight = 32000

export const contactPersonTitles = [
	{
		value: 'distribution_manager',
		label: 'Distribution Manager'
	},
	{
		value: 'manager',
		label: 'Manager'
	},
	{
		value: 'director',
		label: 'Director'
	},
	{
		value: 'employee',
		label: 'Employee'
	},
	{
		value: 'other',
		label: 'Other'
	}
]

export const paymentTerms = [
	{
		value: 30,
		label: '30 days'
	},
	{
		value: 45,
		label: '45 days'
	},
	{
		value: 60,
		label: '60 days'
	}
]

export const LocationTypes = {
	GENERAL: 'GENERAL',
	LOADING: 'LOADING',
	UNLOADING: 'UNLOADING',
	BILLING: 'BILLING',
	SHIPPING: 'SHIPPING',
	HEADQUARTERS: 'HEADQUARTERS',
	WAREHOUSE: 'WAREHOUSE',
	PICKUP: 'PICKUP',
	FACTORY: 'FACTORY',
	VIRTUAL_WAREHOUSE: 'VIRTUAL_WAREHOUSE',
	SITE: 'SITE',
	BRANCH: 'BRANCH'
}

export const locationTypes = [
	{
		label: 'General',
		value: LocationTypes.GENERAL
	},
	{
		label: 'Loading',
		value: LocationTypes.LOADING
	},
	{
		label: 'Unloading',
		value: LocationTypes.UNLOADING
	},
	{
		label: 'Billing',
		value: LocationTypes.BILLING
	},
	{
		label: 'Shipping',
		value: LocationTypes.SHIPPING
	},
	{
		label: 'Warehouse',
		value: LocationTypes.WAREHOUSE
	},
	{
		label: 'Pick Up',
		value: LocationTypes.PICKUP
	},
	{
		label: 'Factory',
		value: LocationTypes.FACTORY
	},
	{
		label: 'Site',
		value: LocationTypes.SITE
	},
	{
		label: 'Branch',
		value: LocationTypes.BRANCH
	}
]

export const orderStatuses = {
	SALES_ORDER_PENDING_STATUS: 'pending',
	SALES_ORDER_ON_HOLD_STATUS: 'on_hold',
	SALES_ORDER_REQUESTED_STATUS: 'requested',
	SALES_ORDER_FLAGGED_STATUS: 'flagged',
	SALES_ORDER_APPROVED_STATUS: 'approved',
	SALES_ORDER_PROCESSING_STATUS: 'processing',
	SALES_ORDER_COMPLETED_STATUS: 'completed',
	SALES_ORDER_SHIPPED_STATUS: 'shipped',
	SALES_ORDER_IN_TRANSIT_STATUS: 'in_transit',
	SALES_ORDER_DELIVERED_STATUS: 'delivered',
	SALES_ORDER_CANCELLED_STATUS: 'cancelled',
	SALES_ORDER_DAMAGED_STATUS: 'damaged',
	SALES_ORDER_RETURNED_STATUS: 'returned',
	SALES_ORDER_PENDING_RETURNED_STATUS: 'pending_returned',
	SALES_ORDER_EXPIRED_STATUS: 'expired',
	SALES_ORDER_PICKING_STATUS: 'picking',
	SALES_ORDER_PACKING_STATUS: 'packing',
	SALES_ORDER_PAYMENT_COLLECTED_STATUS: 'payment_collected',
	SALES_ORDER_PAYMENT_PAID_STATUS: 'payment_paid',
	SALES_ORDER_PAYMENT_DUE_STATUS: 'payment_due',
	SALES_ORDER_ARRIVED_STATUS: 'arrived',
	SALES_ORDER_UNLOADED_STATUS: 'unloaded',
	SALES_ORDER_RECEIVED_STATUS: 'received'
}

export const SALES_ORDER_STATUS_COLORS = {
	[orderStatuses.SALES_ORDER_REQUESTED_STATUS]: '#00171D',
	[orderStatuses.SALES_ORDER_FLAGGED_STATUS]: '#FF7A7A',
	[orderStatuses.SALES_ORDER_APPROVED_STATUS]: '#288EA5',
	[orderStatuses.SALES_ORDER_PROCESSING_STATUS]: '#F6B44F',
	[orderStatuses.SALES_ORDER_COMPLETED_STATUS]: '#288EA5',
	[orderStatuses.SALES_ORDER_CANCELLED_STATUS]: '#FF5959'
}

export const SALES_ORDER_SUB_STATUS_COLORS = {
	[orderStatuses.SALES_ORDER_SHIPPED_STATUS]: '#00C0C1',
	[orderStatuses.SALES_ORDER_IN_TRANSIT_STATUS]: '#2D9CDB',
	[orderStatuses.SALES_ORDER_UNLOADED_STATUS]: '#00BA88',
	[orderStatuses.SALES_ORDER_PAYMENT_COLLECTED_STATUS]: '#18A188',
	[orderStatuses.SALES_ORDER_PAYMENT_DUE_STATUS]: '#F6BA5F',
	[orderStatuses.SALES_ORDER_RETURNED_STATUS]: '#8C1F28',
	[orderStatuses.SALES_ORDER_DAMAGED_STATUS]: '#591C21',
	[orderStatuses.SALES_ORDER_EXPIRED_STATUS]: '#D92525',
	[orderStatuses.SALES_ORDER_PENDING_RETURNED_STATUS]: '#FF7A7A'
}

export const eCommerceOrderTabs = [
	{
		title: 'All Orders',
		status: 'all',
		path: `${paths.SALES_ORDERS}?status=all`
	},
	{
		title: 'Pending',
		status: orderStatuses.SALES_ORDER_REQUESTED_STATUS,
		path: `${paths.SALES_ORDERS}?status=${orderStatuses.SALES_ORDER_REQUESTED_STATUS}`
	},
	{
		title: 'On Hold',
		status: orderStatuses.SALES_ORDER_ON_HOLD_STATUS,
		path: `${paths.SALES_ORDERS}?status=${orderStatuses.SALES_ORDER_ON_HOLD_STATUS}`
	},
	{
		title: 'Approved',
		status: orderStatuses.SALES_ORDER_APPROVED_STATUS,
		path: `${paths.SALES_ORDERS}?status=${orderStatuses.SALES_ORDER_APPROVED_STATUS}`
	},
	{
		title: 'Processing',
		status: orderStatuses.SALES_ORDER_PROCESSING_STATUS,
		path: `${paths.SALES_ORDERS}?status=${orderStatuses.SALES_ORDER_PROCESSING_STATUS}`
	},
	{
		title: 'Shipped',
		status: orderStatuses.SALES_ORDER_SHIPPED_STATUS,
		path: `${paths.SALES_ORDERS}?status=${orderStatuses.SALES_ORDER_SHIPPED_STATUS}`
	},
	{
		title: 'In-Transit',
		status: orderStatuses.SALES_ORDER_IN_TRANSIT_STATUS,
		path: `${paths.SALES_ORDERS}?status=${orderStatuses.SALES_ORDER_IN_TRANSIT_STATUS}`
	},
	{
		title: 'Delivered',
		status: orderStatuses.SALES_ORDER_COMPLETED_STATUS,
		path: `${paths.SALES_ORDERS}?status=${orderStatuses.SALES_ORDER_COMPLETED_STATUS}`
	},
	{
		title: 'Flagged',
		status: orderStatuses.SALES_ORDER_FLAGGED_STATUS,
		path: `${paths.SALES_ORDERS}?status=${orderStatuses.SALES_ORDER_FLAGGED_STATUS}&subStatus=${orderStatuses.SALES_ORDER_PENDING_RETURNED_STATUS}`
	},
	{
		title: 'Cancelled',
		status: orderStatuses.SALES_ORDER_CANCELLED_STATUS,
		path: `${paths.SALES_ORDERS}?status=${orderStatuses.SALES_ORDER_CANCELLED_STATUS}`
	}
]

export const defaultCustomerTags = {
	NEW: 'NEW',
	REGULAR: 'REGULAR',
	VIP: 'VIP',
	FRAUD: 'FRAUD'
}

export const shippingAreas = {
	DHAKA: 'DHAKA',
	DHAKA_SUBURB: 'DHAKA_SUBURB',
	OUTSIDE_DHAKA: 'OUTSIDE_DHAKA'
}

export const deliveryPlanStatuses = {
	DELIVERY_PLAN_PROCESSING_STATUS: 'processing',
	DELIVERY_PLAN_PENDING_STATUS: 'pending',
	DELIVERY_PLAN_APPROVED_STATUS: 'approved',
	DELIVERY_PLAN_EN_ROUTE_STATUS: 'en_route',
	DELIVERY_PLAN_SCHEDULED_STATUS: 'scheduled',
	DELIVERY_PLAN_DELIVERED_STATUS: 'delivered',
	DELIVERY_PLAN_FAILED_STATUS: 'failed',
	DELIVERY_PLAN_CANCELLED_STATUS: 'cancelled'
}

export const deliveryPlanItemStatuses = {
	ROUTE_GENERATED_STATUS: 'generated',
	ROUTE_SHIPPED_STATUS: 'shipped',
	ROUTE_IN_TRANSIT_STATUS: 'in_transit',
	ROUTE_UNLOADED_STATUS: 'unloaded',
	ROUTE_COMPLETED_STATUS: 'completed',
	ROUTE_CANCELLED_STATUS: 'cancelled'
}

export const vehicleRequestStatuses = {
	VEHICLE_REQUEST_APPROVED_STATUS: 'APPROVED',
	VEHICLE_REQUEST_DENIED_STATUS: 'DENIED',
	VEHICLE_REQUEST_PENDING_STATUS: 'PENDING'
}

export const returnOrCancelReasons = {
	DAMAGED_PRODUCT: 'Damaged product',
	DELAY_DELIVERY: 'Delay delivery',
	WRONG_PRODUCT: 'Wrong product',
	OUT_OF_ZONE: 'Out of zone',
	FRAUD_CUSTOMER: 'Fraud customer',
	DELIVERY_MAN_CARELESSNESS: 'Delivery man\'s carelessness',
	HIGH_PRICE: 'High price',
	SHORT_TIME_DELIVERY: 'Short time delivery',
	DUPLICATE_ORDER: 'Duplicate Order',
	FAKE_ORDER: 'Fake Order',
	CHANGED_MIND: 'Changed Mind',
	OTHER_REASON: 'Other'
}

export const onHoldReasons = {
	PHONE_NUMBER_UNREACHABLE: 'Phone Number Unreachable',
	CALL_NOT_ANSWERED: 'Call Not Answered',
	FOLLOW_UP_CALL_SCHEDULED: 'Follow-up Call Scheduled',
	INVALID_PHONE_NUMBER: 'Invalid Phone Number',
	AWAITING_CUSTOMER_DECISION: 'Awaiting Customer Decision',
	PRE_ORDER: 'Pre-Order',
	OUT_OF_STOCK: 'Out of Stock',
	AWAITING_PAYMENT_CONFIRMATION: 'Awaiting Payment Confirmation',
	ADDITIONAL_PRODUCT_REQUIRED: 'Additional Product Required',
	DELIVERY_ADDRESS_UPDATED: 'Delivery Address Updated',
	DELIVERY_DATE_UPDATED: 'Delivery Date Updated',
	OTHER: 'Other'
}

export const AddressTypes = {
	HOME: 'home',
	WORK: 'work',
	OTHERS: 'others'
}

export const FileTypes = {
	IMAGE: 'image',
	PDF: 'pdf',
	CSV: 'csv',
	EXCEL: 'excel',
	OTHER: 'other'
}

export const InventoryEditions = {
	BASIC: 'BASIC',
	STANDARD: 'STANDARD',
	ENTERPRISE: 'ENTERPRISE',
	ADVANCED: 'ADVANCED'
}

export const DistributorOrderNotificationValueType = {
	ORDER_VALUE: 'ORDER_VALUE',
	OWING_AMOUNT: 'OWING_AMOUNT'
}

export const StandardInventoryProductTypes = {
	FINISHED_GOODS: 'FINISHED_GOODS',
	RAW_MATERIAL: 'RAW_MATERIAL'
}

export const standardInventoryProductTabs = [
	{
		title: 'Finished Goods',
		type: StandardInventoryProductTypes.FINISHED_GOODS,
		path: `${paths.PRODUCTS}`
	},
	{
		title: 'Raw Material',
		type: StandardInventoryProductTypes.RAW_MATERIAL,
		path: `${paths.PRODUCTS}?type=${StandardInventoryProductTypes.RAW_MATERIAL}`
	}
]

export const globalSearchLocalStorageKeys = {
	NUPORT_RECENT_SALES_ORDERS_SEARCH_RESULT: 'nuportRecentSalesOrdersSearchResult',
	NUPORT_RECENT_DELIVERY_PLANS_SEARCH_RESULT: 'nuportRecentDeliveryPlansSearchResult',
	NUPORT_RECENT_PRODUCTS_SEARCH_RESULT: 'nuportRecentProductsSearchResult',
	NUPORT_RECENT_CUSTOMERS_SEARCH_RESULT: 'nuportRecentCustomersSearchResult',
	NUPORT_RECENT_VEHICLES_SEARCH_RESULT: 'nuportRecentVehiclesSearchResult',
	NUPORT_RECENT_USERS_SEARCH_RESULT: 'nuportRecentUsersSearchResult',
	NUPORT_RECENT_REPORTS_SEARCH_RESULT: 'nuportRecentReportsSearchResult',
	NUPORT_RECENT_INVENTORY_SEARCH_RESULT: 'nuportRecentInventorySearchResult',
	NUPORT_RECENT_COMPANIES_SEARCH_RESULT: 'nuportRecentCompaniesSearchResult',
	NUPORT_RECENT_LOCATIONS_SEARCH_RESULT: 'nuportRecentLocationsSearchResult',
	NUPORT_RECENT_DELIVERY_PARTNERS_SEARCH_RESULT: 'nuportRecentDeliveryPartnersSearchResult',
	NUPORT_RECENT_EXPENSES_SEARCH_RESULT: 'nuportRecentExpensesSearchResult',
	NUPORT_RECENT_VENDORS_SEARCH_RESULT: 'nuportRecentVendorsSearchResult',
	NUPORT_RECENT_PURCHASE_ORDERS_SEARCH_RESULT: 'nuportRecentPurchaseOrdersSearchResult',
	NUPORT_RECENT_BILLING_INVOICE_SEARCH_RESULT: 'nuportRecentBillingInvoiceSearchResult',
	NUPORT_RECENT_BILLING_PAYMENT_SEARCH_RESULT: 'nuportRecentBillingPaymentSearchResult'
}

export const InvoiceTypes = {
	GENERAL: 'GENERAL',
	POS: 'POS'
}

export const InvoiceScanTypes = {
	BARCODE: 'BARCODE',
	QR: 'QR'
}

export const Apps = {
	OMS: 'OMS',
	WMS: 'WMS',
	DMS: 'DMS',
	TMS: 'TMS'
}

export const apps = [
	{
		icon: {
			src: '/img/stacked-box.svg',
			width: 33,
			height: 40,
			alt: 'Box on top of a box icon'
		},
		title: 'OMS',
		value: Apps.OMS,
		tooltip: 'Order Management System'
	},
	{
		icon: {
			src: '/img/route.svg',
			width: 40,
			height: 40,
			alt: 'Route on a map icon'
		},
		title: 'DMS',
		value: Apps.DMS,
		tooltip: 'Delivery Management System'
	},
	{
		icon: {
			src: '/img/warehouse.svg',
			width: 48,
			height: 48,
			alt: 'Warehouse icon'
		},
		title: 'WMS',
		value: Apps.WMS,
		tooltip: 'Warehouse Management System'
	},
	{
		icon: {
			src: '/img/cargo-truck.svg',
			width: 40,
			height: 40,
			alt: 'Truck icon'
		},
		title: 'TMS',
		value: Apps.TMS,
		tooltip: 'Transport Management System'
	}
]

export const MAX_GLOBAL_RECENT_SEARCH_COUNT = 5

export const ThirdPartyLogisticsType = {
	OTHER: 'OTHER',
	GIM: 'GIM'
}

export const deliveryPartnerLogos = {
	[PartnerType.STEADFAST]: {
		src: '/img/steadfast.svg',
		alt: 'Steadfast logo'
	},
	[PartnerType.PATHAO]: {
		src: '/img/pathao.svg',
		alt: 'Pathao logo'
	},
	[PartnerType.REDX]: {
		src: '/img/redx.svg',
		alt: 'Redx logo'
	},
	[PartnerType.E_COURIER]: {
		src: '/img/e-courier.svg',
		alt: 'E-Courier logo'
	},
	[PartnerType.PAPERFLY]: {
		src: '/img/paperfly.svg',
		alt: 'Paperfly logo'
	},
	[PartnerType.CUSTOM]: {
		src: '/img/delivery-partner.svg',
		alt: 'Delivery Partner logo'
	},
	[PartnerType.PAKISTAN_POST]: {
		src: '/img/pakistan-post.svg',
		alt: 'Pakistan Post logo'
	},
	[PartnerType.PIDEX]: {
		src: '/img/pidex.svg',
		alt: 'Pidex logo'
	},
	[PartnerType.TCS_COURIER]: {
		src: '/img/tcs-courier.svg',
		alt: 'TCS Courier logo'
	},
	[PartnerType.RIDER_COURIER]: {
		src: '/img/rider-courier.svg',
		alt: 'Rider Courier logo'
	},
	[PartnerType.POST_EX]: {
		src: '/img/post-ex.svg',
		alt: 'PostEx logo'
	},
	[PartnerType.MP_COURIER]: {
		src: '/img/mp-courier.svg',
		alt: 'M & P Courier logo'
	},
	[PartnerType.LEOPARDS]: {
		src: '/img/leopards.svg',
		alt: 'Leopards logo'
	},
	[PartnerType.BLUE_EX]: {
		src: '/img/blue-ex.svg',
		alt: 'BlueEx logo'
	},
	[PartnerType.MOVEX]: {
		src: '/img/movex.svg',
		alt: 'MoveX logo'
	},
	[PartnerType.PANDAGO]: {
		src: '/img/pandago.svg',
		alt: 'Pandago logo'
	},
	[PartnerType.XPRESS_IN_TOWN]: {
		src: '/img/xpress-in-town.svg',
		alt: 'Xpress In Town logo'
	}
}

export const PREVIOUSLY_USED_ORDER_SOURCE_KEY = 'previously_used_order_source_key'
export const PREVIOUSLY_SELECTED_PICK_UP_LOCATION = 'previouslySelectedPickUpAddress'

export const TransactionType = {
	ADVANCE: 'ADVANCE',
	FEE: 'FEE',
	PAYMENT: 'PAYMENT',
	REFUND: 'REFUND'
}

export const PaymentStatus = {
	PENDING: 'PENDING',
	FAILED: 'FAILED',
	APPROVED: 'APPROVED'
}

export const SubscriptionTier = {
	INACTIVE: 'INACTIVE',
	BASIC: 'BASIC',
	PRO: 'PRO',
	ADVANCED: 'ADVANCED',
	PLATINUM: 'PLATINUM'
}

export const LEGACY_SUBSCRIPTION_PLAN = 'BASIC_TRIAL'

export const subscriptionTierDetails = [
	{
		label: 'Basic',
		value: 'BASIC',
		description: [
			'Up To 3,500 Sales Orders/month',
			'Up To 500 SKUs',
			'Up To 10 Users (Extra cost ৳200/user)'
		]
	},
	{
		label: 'Pro',
		value: 'PRO',
		description: [
			'From 3,501 - 12,000 Sales Orders/month',
			'Up To 100 Purchase Orders/month',
			'Up To 1,000 SKUs',
			'Up To 20 Users (Extra cost ৳200/user)'
		]
	},
	{
		label: 'Advanced',
		value: 'ADVANCED',
		description: [
			'From 12,001 - 20,000 Sales Orders/month',
			'101 - 300 Purchase Orders/month',
			'Up To 2,500 SKUs',
			'Up To 35 Users (Extra cost  ৳200/user)'
		]
	},
	{
		label: 'Platinum',
		value: 'PLATINUM',
		description: [
			'From 20,001 - 30,000 Sales Orders/month',
			'301 - 1000 Purchase Orders/month',
			'Up To 5,000 SKUs',
			'Up To 50 Users (Extra cost  ৳200/user)'
		]
	}
]

export const SubscriptionPeriod = {
	MONTHLY: {
		label: 'Month',
		value: 'MONTHLY'
	},
	YEARLY: {
		label: 'Year',
		value: 'YEARLY'
	}
}

export const PaymentGateway = {
	SSLCOMMERZ: 'SSLCOMMERZ',
	STRIPE: 'STRIPE',
	BKASH: 'BKASH'
}

export const PaymentsModalType = {
	PAY: 'PAY',
	CHANGE_PLAN: 'CHANGE_PLAN'
}

export const PaymentType = {
	SUBSCRIPTION: {
		value: 'SUBSCRIPTION',
		label: 'Subscription'
	},
	SMS: {
		value: 'SMS',
		label: 'Buy SMS'
	},
	CCL: {
		value: 'CCL',
		label: 'Buy DSR Request'
	},
	SUBSCRIPTION_RENEWAL: {
		value: 'SUBSCRIPTION_RENEWAL',
		label: 'Subscription Renewal'
	},
	SUBSCRIPTION_CHANGE: {
		value: 'SUBSCRIPTION_CHANGE',
		label: 'Subscription Change'
	},
	SUBSCRIPTION_PRORATED_CHANGE: {
		value: 'SUBSCRIPTION_PRORATED_CHANGE',
		label: 'Subscription Prorated Change'
	},
	SUBSCRIPTION_PERIOD_CHANGE: {
		value: 'SUBSCRIPTION_PERIOD_CHANGE',
		label: 'Subscription Period Change'
	},
	ONE_TIME: {
		value: 'ONE_TIME',
		label: 'One Time'
	},
	UPSELL: {
		value: 'UPSELL',
		label: 'Upsell'
	}
}

export const defaultPricing = {
	basicTier: 5000,
	proTier: 10000,
	advancedTier: 15000,
	smsRate: 0.28,
	vatRate: 0.05,
	taxRate: 0
}

export const SubscriptionStatus = {
	TRIALING: 'TRIALING',
	ACTIVE: 'ACTIVE',
	INCOMPLETE: 'INCOMPLETE',
	INCOMPLETE_EXPIRED: 'INCOMPLETE_EXPIRED',
	PAST_DUE: 'PAST_DUE',
	CANCELED: 'CANCELED',
	UNPAID: 'UNPAID',
	NOT_SUBSCRIBED: 'NOT_SUBSCRIBED'
}

export const SubscriptionCancelSource = {
	ADMIN: 'ADMIN',
	TRIAL_END: 'TRIAL_END'
}

export const InventoryLogRemarkOptions = {
	PURCHASE_RAW_MATERIAL : 'PURCHASE_RAW_MATERIAL',
	SELL_FINISHED_GOODS : 'SELL_FINISHED_GOODS',
	MANUALLY_ADDED : 'MANUALLY_ADDED',
	PROCESSED_TO_FINISHED_GOODS : 'PROCESSED_TO_FINISHED_GOODS',
	PRODUCT_DAMAGED : 'PRODUCT_DAMAGED',
	PRODUCT_RETURNED : 'PRODUCT_RETURNED'
}

export const priceTypes = {
	DEFAULT: 'DEFAULT',
	SALE: 'SALE',
	RESELL: 'RESELL',
	PURCHASE: 'PURCHASE',
	DISTRIBUTOR: 'DISTRIBUTOR',
	DEALER: 'DEALER',
	RETAILER: 'RETAILER',
	E_COMMERCE_CUSTOMER: 'E_COMMERCE_CUSTOMER',
	TRADER: 'TRADER',
	MICRO_ENTREPRENEUR: 'MICRO_ENTREPRENEUR',
	CORPORATE: 'CORPORATE'
}

export const eCommercePriceTypeFields = [
	{
		title: 'MRP',
		type: priceTypes.DEFAULT,
		tooltip: 'Maximum Retail Price',
		field: 'price'
	},
	{
		title: 'Retail Price',
		type: priceTypes.RETAILER,
		field: 'retailPrice'
	},
	{
		title: 'Distributor Price',
		type: priceTypes.DISTRIBUTOR,
		field: 'distributorPrice'
	},
	{
		title: 'Purchase Price',
		type: priceTypes.PURCHASE,
		field: 'purchasePrice'
	}
]

export const priceTypeFields = [
	{
		title: 'Price',
		type: priceTypes.DEFAULT,
		field: 'price'
	},
	{
		title: 'Resell Price',
		type: priceTypes.RESELL,
		field: 'resellPrice'
	},
	{
		title: 'Purchase Price',
		type: priceTypes.PURCHASE,
		field: 'purchasePrice'
	}
]

export const agriculturePriceTypeFields = [
	{
		title: 'Distributor Price',
		type: priceTypes.DISTRIBUTOR,
		field: 'distributorPrice'
	},
	{
		title: 'Dealer Price',
		type: priceTypes.DEALER,
		field: 'dealerPrice'
	},
	{
		title: 'Retailer Price',
		type: priceTypes.RETAILER,
		field: 'retailPrice'
	},
	{
		title: 'E-Commerce Customer Price',
		type: priceTypes.E_COMMERCE_CUSTOMER,
		field: 'eCommerceCustomerPrice'
	},
	{
		title: 'Trader Price',
		type: priceTypes.TRADER,
		field: 'traderPrice'
	},
	{
		title: 'Micro Entrepreneur Price',
		type: priceTypes.MICRO_ENTREPRENEUR,
		field: 'microEntrepreneurPrice'
	},
	{
		title: 'Corporate Price',
		type: priceTypes.CORPORATE,
		field: 'corporatePrice'
	},
	{
		title: 'Purchase Price',
		type: priceTypes.PURCHASE,
		field: 'purchasePrice'
	}
]

export const PurchaseOrderStatus = {
	REQUESTED: 'requested',
	APPROVED: 'approved',
	RECEIVED: 'received',
	FLAGGED: 'flagged',
	CANCELLED: 'cancelled'
}

export const PurchaseOrderSubStatus = {
	RETURNED: 'returned',
	DAMAGED: 'damaged',
	EXPIRED: 'expired',
	PAYMENT_DUE: 'payment_due',
	PAYMENT_PAID: 'payment_paid'
}

export const purchaseOrderSubStatuses = [
	{
		status: PurchaseOrderStatus.FLAGGED,
		subStatus: PurchaseOrderSubStatus.RETURNED
	},
	{
		status: PurchaseOrderStatus.FLAGGED,
		subStatus: PurchaseOrderSubStatus.DAMAGED
	},
	{
		status: PurchaseOrderStatus.FLAGGED,
		subStatus: PurchaseOrderSubStatus.EXPIRED
	},
	{
		status: PurchaseOrderStatus.RECEIVED,
		subStatus: PurchaseOrderSubStatus.PAYMENT_DUE
	},
	{
		status: PurchaseOrderStatus.RECEIVED,
		subStatus: PurchaseOrderSubStatus.PAYMENT_PAID
	}
]

export const SubscriptionAction = {
	SUBSCRIBE_NOW: 'subscribe-now',
	RENEW_SUBSCRIPTION: 'renew-subscription',
	MAKE_ONE_TIME_PAYMENT: 'make-one-time-payment',
	VIEW_INVOICE: 'view-invoice',
	VIEW_LATEST_INVOICE: 'view-latest-invoice',
	VIEW_PAYMENT: 'view-payment',
	VIEW_PLANS: 'view-plans',
	VIEW_ONE_TIME_INVOICE: 'view-one-time-invoice',
	VIEW_UPSELL_INVOICE: 'view-upsell-invoice',
	TOP_UP_SMS: 'top-up-sms',
	TOP_UP_CCL: 'top-up-ccl',
	MAKE_UPSELL_PAYMENT: 'make-upsell-payment'
}

export const SubscriptionLimit = {
	SALES_ORDERS: 'createSalesOrdersPerMonth',
	PURCHASE_ORDERS: 'createPurchaseOrdersPerMonth',
	TOTAL_PRODUCTS: 'totalProducts',
	COMPANY_USERS: 'companyUsers'
}

export const subscriptionTierFeaturesData = [
	{
		feature: 'Sales Order Management',
		basic: ['Up To 3,500 orders/month'],
		pro: ['From 3501 - 12,000 orders/month'],
		advanced: ['From 12,001 - 20,000 orders/month'],
		platinum: ['From 20,001 - 30,000 orders/month']
	},
	{
		feature: 'Purchase Order Management',
		basic: [],
		pro: ['Up To 100 orders/month'],
		advanced: ['From 101-300 orders/month'],
		platinum: ['From 301-1,000 orders/month']
	},
	{
		feature: 'Users',
		basic: ['Up To 10 Users (Extra cost ৳200/user)'],
		pro: ['Up To 20 Users (Extra cost ৳200/user)'],
		advanced: ['Up To 35 Users (Extra cost ৳200/user)'],
		platinum: ['Up To 50 Users (Extra cost ৳200/user)']
	},
	{
		feature: 'Product SKUs',
		basic: ['Up To 500 SKUs'],
		pro: ['Up To 1,000 SKUs'],
		advanced: [
			'Up to 2,500 SKUs',
			'Advanced Categorization',
			'Individual Product Sales Intelligence',
			'Individual Product Sales Records'
		],
		platinum: [
			'Up to 5,000 SKUs',
			'Advanced Categorization',
			'Individual Product Sales Intelligence',
			'Individual Product Sales Records'
		]
	},
	{
		feature: 'Customer Portal',
		basic: [
			'Add/Edit Customers',
			'Geocode Customers'
		],
		pro: [
			'Add/Edit/Upload Customers',
			'Geocode Customers',
			'Map View To Monitor Customer Density',
			'Customer Order Pattern Charts',
			'Customer Notes with Time Log'
		],
		advanced: [
			'Add/Edit/Upload Customers',
			'Geocode Customers',
			'Map View To Monitor Customer Density',
			'Customer Order Pattern Charts',
			'Customer Notes with Time Log',
			'CRM Integrations Upon Request'
		],
		platinum: [
			'Add/Edit/Upload Customers',
			'Geocode Customers',
			'Map View To Monitor Customer Density',
			'Customer Order Pattern Charts',
			'Customer Notes with Time Log',
			'CRM Integrations Upon Request'
		]
	}
]

export const paymentMerchants = [
	{
		name: 'Visa',
		logoURL: '/img/visa-logo.svg'
	},
	{
		name: 'MasterCard',
		logoURL: '/img/mastercard-logo.svg'
	}
]

export const ecommerceSignUpQuestionnaireUrl = {
	'BD': 'https://api.hsforms.com/submissions/v3/integration/submit/19649613/6fe85421-71b4-4dff-a91a-a7e9cb56b4fd',
	'PK': 'https://api.hsforms.com/submissions/v3/integration/submit/19649613/b12b4373-8bd3-4106-9a5a-9a23fc7d44d8'
}

export const SubscriptionTrialUpdate = {
	EXTEND: 'extend',
	SHORTEN: 'shorten'
}

export const BillingInvoiceType = {
	SUBSCRIPTION: 'SUBSCRIPTION',
	SMS: 'SMS',
	ONE_TIME: 'ONE_TIME',
	UPSELL: 'UPSELL'
}

export const BillingInvoiceStatus = {
	UNPAID: 'UNPAID',
	VOID: 'VOID',
	PAID: 'PAID'
}

export const BillingPaymentStatus = {
	PAID: 'PAID',
	RECONCILED: 'RECONCILED',
	FAILED: 'FAILED'
}

export const invoiceStatusOptions = [
	{
		title: 'Unpaid',
		label: 'Unpaid',
		value: BillingInvoiceStatus.UNPAID
	},
	{
		title: 'Paid',
		label: 'Paid',
		value: BillingInvoiceStatus.PAID
	},
	{
		title: 'Void',
		label: 'Void',
		value: BillingInvoiceStatus.VOID
	}
]

export const paymentStatusOptions = [
	{
		title: 'Paid',
		label: 'Paid',
		value: BillingPaymentStatus.PAID
	},
	{
		title: 'Reconciled',
		label: 'Reconciled',
		value: BillingPaymentStatus.RECONCILED
	},
	{
		title: 'Failed',
		label: 'Failed',
		value: BillingPaymentStatus.FAILED
	}
]

export const paymentMethodOptions = [
	{
		label: 'Bank Transfer',
		value: 'BANK_TRANSFER'
	},
	{
		label: 'Cheque',
		value: 'CHEQUE'
	},
	{
		label: 'Online Payment',
		value: 'ONLINE_PAYMENT'
	}
]

export const defaultBillingInvoiceFields = {
	authorizationName: 'Kazi Kawshar Ahmed',
	authorizationDesignation: 'Sr. Accounts Officer',
	authorizationCompanyName: 'Nuport Industrial Automation',
	contactName: 'Nuport Industrial Automation, Inc.',
	contactNumberOne: '+8809613274550',
	contactNumberTwo: '+880241002020'
}

export const localStorageKeys = {
	IS_MAP_TRAFFIC_LAYER_ENABLED: 'IS_MAP_TRAFFIC_LAYER_ENABLED',
	PRODUCT_TABLE_MODIFIED_COLUMNS_BASIC_INVENTORY: 'productTableModifiedColumnsBasicInventory',
	PRODUCT_TABLE_MODIFIED_COLUMNS_STANDARD_INVENTORY: 'productTableModifiedColumnsStandardInventory',
	LAST_ORDERED_PRODUCTS_BY_GROUPED_VARIANT: 'last_ordered_products_by_grouped_variant',
	LAST_ORDERED_PRODUCTS_BY_INDIVIDUAL_VARIANT: 'last_ordered_products_by_individual_variant',
	LAST_DELIVERY_ADDRESS_TO_DELIVERY_AREA_MAPPING: 'lastDeliveryAddressToDeliveryAreaMapping',
	LAST_ORDERED_PRODUCT_MAPPING: 'lastOrderedProductMapping'
}

export const EXPORT_TYPE = {
	CSV: 'CSV',
	PDF: 'PDF',
	XLSX: 'XLSX'
}

export const integrationTypes = {
	WOOCOMMERCE: 'WOOCOMMERCE',
	SHOPIFY: 'SHOPIFY',
	DARAZ: 'DARAZ',
	MAGENTO: 'MAGENTO',
	FACEBOOK: 'FACEBOOK'
}

export const eCommerceIntegrations = {
	[integrationTypes.DARAZ]: '/img/daraz.svg',
	[integrationTypes.SHOPIFY]: '/img/shopify.svg',
	[integrationTypes.WOOCOMMERCE]: '/img/woocommerce.svg',
	[integrationTypes.MAGENTO]: '/img/magento.svg',
	[integrationTypes.FACEBOOK]: '/img/facebook.svg'
}

export const sourceTagColors = {
	FACEBOOK: { background: '#3975EA1A', color: '#3975EA' },
	WEBSITE: { background: '#F3B8421A', color: '#F3B842' },
	PHONE_CALL: { background: '#288EA51A', color: '#288EA5' },
	OFFLINE: { background: '#0F3E491A', color: '#0F3E49' },
	OTHER: { background: '#19B0DA1F', color: '#19B0DA' },
	UP_SELL: { background: '#8075F91F', color: '#8075F9' },
	MESSENGER: { background: '#C13ED21F', color: '#C13ED2' },
	WHATSAPP: { background: '#25D3661F', color: '#25D366' },
	TIKTOK: { background: '#00F2EA28', color: '#00F2EA' },
	INSTAGRAM: { background: '#FF65414B', color: '#FF6541' },
	LIVE_CHAT: { background: '#FFA5001F', color: '#FFA500' },
	TELESALES: { background: '#6A5ACD1F', color: '#6A5ACD' }
}

export const timeSpan = {
	today: 'today',
	yesterday: 'yesterday',
	week: 'week',
	month: 'month',
	currentWeek: 'currentWeek',
	currentMonth: 'currentMonth',
	year: 'year',
	lifetime: 'lifetime',
	custom: 'custom'
}

export const packageTypeLabels = {
	CARTON: 'Cartons',
	BOX: 'Boxes',
	BAG: 'Bags',
	CRATE: 'Crates',
	SACK: 'Sacks'
}

export const packageUnitLabels = {
	PIECE: 'Pieces',
	PACKET: 'Packets',
	LITRE: 'Litres',
	KILOGRAM: 'Kilograms'
}

export const dateTimeDisplayFormats = {
	DATE: 'date',
	DATE_TIME: 'date_time'
}

export const specificationTypes = {
	NUMBER: 'NUMBER',
	STRING: 'STRING',
	BOOLEAN: 'BOOLEAN',
	WEIGHT: 'WEIGHT',
	VOLUME: 'VOLUME',
	LENGTH: 'LENGTH'
}

export const ecommerceSubscriptionEvent = {
	TRIALING: 'TRIALING',
	LIVE: 'LIVE',
	CHURNED: 'CHURNED'
}

export const userLogEvent = {
	DISABLED: 'disabled',
	ENABLED: 'enabled'
}

export const salesOrderStatusOptions = [
	{
		label: orderStatuses.SALES_ORDER_REQUESTED_STATUS,
		value: 'Pending'
	},
	{
		label: orderStatuses.SALES_ORDER_APPROVED_STATUS,
		value: 'Approved'
	},
	{
		label: orderStatuses.SALES_ORDER_FLAGGED_STATUS,
		value: 'Flagged'
	},
	{
		label: orderStatuses.SALES_ORDER_PROCESSING_STATUS,
		value: 'Processing'
	},
	{
		label: orderStatuses.SALES_ORDER_COMPLETED_STATUS,
		value: 'Completed'
	},
	{
		label: orderStatuses.SALES_ORDER_CANCELLED_STATUS,
		value: 'Cancelled'
	},
	{
		label: orderStatuses.SALES_ORDER_SHIPPED_STATUS,
		value: 'Shipped'
	},
	{
		label: orderStatuses.SALES_ORDER_IN_TRANSIT_STATUS,
		value: 'In Transit'
	},
	{
		label: orderStatuses.SALES_ORDER_ON_HOLD_STATUS,
		value: 'On Hold'
	},
	{
		label: orderStatuses.SALES_ORDER_RETURNED_STATUS,
		value: 'Returned'
	},
	{
		label: orderStatuses.SALES_ORDER_DAMAGED_STATUS,
		value: 'Damaged'
	},
	{
		label: orderStatuses.SALES_ORDER_EXPIRED_STATUS,
		value: 'Expired'
	},
	{
		label: orderStatuses.SALES_ORDER_PAYMENT_DUE_STATUS,
		value: 'Delivered Payment Due'
	},
	{
		label: orderStatuses.SALES_ORDER_PAYMENT_COLLECTED_STATUS,
		value: 'Delivered Payment Collected'
	}
]

export const productQuantityInputFormats = {
	INTEGER: 'integer',
	FRACTION: 'fraction'
}

export const productUpsellModes = {
	NONE: 'none',
	CREATE_ORDER: 'create_order',
	EDIT_ORDER: 'edit_order',
	BOTH: 'both'
}

export const autoApproveOrderModes = {
	NONE: 'none',
	CREATE_ORDER: 'create_order',
	EDIT_ORDER: 'edit_order',
	BOTH: 'both'
}

export const autoApproveOrderTypes = {
	MANUAL: 'manual',
	WEBSITE: 'website'
}

export const productListViewModes = {
	GROUPED_VARIANT: 'grouped_variant',
	INDIVIDUAL_VARIANT: 'individual_variant'
}

export const inventoryCheckModes = {
	CHECK_INVENTORY: 'check_inventory',
	SKIP_INVENTORY_CHECK: 'skip_inventory_check'
}

export const viewLocationTagModes = {
	NONE: 'none',
	DISTRICT: 'district',
	DIVISION: 'division'
}

export const topNotificationType = {
	SUBSCRIPTION_PAST_DUE: 'SUBSCRIPTION_PAST_DUE',
	SUBSCRIPTION_CANCELED: 'SUBSCRIPTION_CANCELED',
	SUBSCRIPTION_EXPIRING: 'SUBSCRIPTION_EXPIRING',
	SUBSCRIPTION_TRIAL_EXPIRING: 'SUBSCRIPTION_TRIAL_EXPIRING',
	SUBSCRIPTION_LIMIT_EXCEEDED: 'SUBSCRIPTION_LIMIT_EXCEEDED',
	SALES_ORDER_LIMIT_EXCEEDED: 'SALES_ORDER_LIMIT_EXCEEDED',
	PURCHASE_ORDER_LIMIT_EXCEEDED: 'PURCHASE_ORDER_LIMIT_EXCEEDED',
	SALES_ORDER_LIMIT_ALMOST_OVER: 'SALES_ORDER_LIMIT_ALMOST_OVER',
	PURCHASE_ORDER_LIMIT_ALMOST_OVER: 'PURCHASE_ORDER_LIMIT_ALMOST_OVER'
}

export const invoicePrintStatusOptions = [
	{
		label: 'Already Printed',
		value: 'PRINTED'
	},
	{
		label: 'Not Yet Printed',
		value: 'UNPRINTED'
	}
]

export const quantityChangeTypeOptions = [
	{
		label: 'Inbound',
		value: 'INBOUND'
	},
	{
		label: 'Outbound',
		value: 'OUTBOUND'
	}
]

export const deliveryPartnerTrackingBaseURLs = {
	[PartnerType.STEADFAST]: 'https://steadfast.com.bd/t',
	[PartnerType.PATHAO]: 'https://merchant.pathao.com/tracking',
	[PartnerType.PIDEX]: 'https://pidex.biz/track',
	[PartnerType.REDX]: 'https://redx.com.bd/track-parcel',
	[PartnerType.E_COURIER]: 'https://ecourier.com.bd/tracking',
	[PartnerType.RIDER_COURIER]: 'https://track.withrider.com/#/track',
	[PartnerType.BLUE_EX]: 'https://beta.blue-ex.com/detail',
	[PartnerType.PAPERFLY]: 'https://go.paperfly.com.bd/track/order',
	[PartnerType.PANDAGO]: 'https://pandago-bd.com/tracking'
}

export const subscriptionLimit = {
	SALES_ORDERS: 'createSalesOrdersPerMonth',
	PURCHASE_ORDERS: 'createPurchaseOrdersPerMonth',
	USERS: 'companyUsers',
	PRODUCTS: 'totalProducts'
}

export const uploadCSVType = {
	PRODUCTS: {
		name: 'Products',
		slug: 'products'
	},
	FLEET: {
		name: 'Fleet',
		slug: 'fleet'
	},
	INVENTORY: {
		name: 'Inventory',
		slug: 'inventory'
	},
	LOCATIONS: {
		name: 'Locations',
		slug: 'locations'
	},
	SALES_ORDERS: {
		name: 'Sales Orders',
		slug: 'sales_orders'
	},
	PURCHASE_ORDERS: {
		name: 'Purchase Orders',
		slug: 'purchase_orders'
	},
	PURCHASE_ORDER_ITEMS: {
		name: 'Purchase Order Items',
		slug: 'purchase_order_items'
	},
	QUOTATIONS: {
		name: 'Quotations',
		slug: 'quotations'
	},
	VENDORS: {
		name: 'Vendors',
		slug: 'vendors'
	},
	DISTRIBUTORS: {
		name: 'Customers',
		slug: 'customers'
	}
}

export const pkBanks = ['Habib Bank Limited', 'National Bank of Pakistan', 'United Bank Limited', 'Al Baraka Bank (Pakistan) Limited', 'Allied Bank Limited ', 'Askari Bank', 'Bank Alfalah Limited', 'Bank Al-Habib Limited', 'BankIslami Pakistan Limited', 'Bank Makramah Limited ', 'Bank of Punjab', 'Deutsche Bank A.G', 'Dubai Islamic Bank Pakistan Limited', 'Faysal Bank Limited ', 'First Women Bank Limited', 'Habib Metropolitan Bank Limited', 'Industrial and Commercial Bank of China', 'Industrial Development Bank of Pakistan', 'JS Bank Limited', 'MCB Bank Limited', 'MCB Islamic Bank Limited', 'Meezan Bank Limited', 'Soneri Bank Limited', 'Standard Chartered Bank (Pakistan) Limited', 'Sindh Bank', 'The Bank of Tokyo-Mitsubishi UFJ', 'Zarai Taraqiati Bank Limited', 'Bank of Azad Jammu & Kashmir', 'Deutsche Bank AG ', 'Citi Bank N.A', 'Industrial and Commercial Bank of China Limited', 'Bank of China', 'The Hong Kong and Shanghai Bank']

export const deliveryTypes = {
	REGULAR: 'REGULAR',
	EXPRESS: 'EXPRESS',
	IN_STORE_PICKUP: 'IN_STORE_PICKUP'
}

export const deliveryTypeColors = {
	[deliveryTypes.REGULAR]: {
		color: '#4CAF50',
		background: '#4CAF501A'
	},
	[deliveryTypes.EXPRESS]: {
		color: '#FF6B6B',
		background: '#FF6B6B1A'
	},
	[deliveryTypes.IN_STORE_PICKUP]: {
		color: '#9C27B0',
		background: '#9C27B01A'
	}
}

export const deliveryTypeOptions = [
	{
		label: 'Regular',
		value: deliveryTypes.REGULAR
	},
	{
		label: 'Express',
		value: deliveryTypes.EXPRESS
	},
	{
		label: 'In-Store Pickup',
		value: deliveryTypes.IN_STORE_PICKUP
	}
]
